import { generatePath } from "react-router-dom";

enum PathParams {
  FunnelId = "funnelId",
}

export const RoutePath = {
  FunnelWireTransferDetailsPage: `f/:${PathParams.FunnelId}/wire-transfer-details`,
};

export type RoutePathPathParams = {
  FunnelWireTransferDetailsPage: {
    [PathParams.FunnelId]: string;
  };
};

export const generateFormalityFunnelViewPath = (
  path: string,
  params: {
    [PathParams.FunnelId]: string | number;
  }
) => {
  return generatePath(path, params);
};
