import { useApp, useFluidLayout, useLegalEntity } from "hooks";
import { LoadingPage } from "pages/loading";
import { useCallback, useEffect, useReducer } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { SubscriptionsService } from "services";
import styled, { css } from "styled-components";
import { SubscriptionViewPathParams } from "../router";
import SubscriptionViewContext from "./SubscriptionViewContext";

const PageContainer = styled.div`
  ${({ theme }) => css`
    > .content-container {
      max-width: 1024px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      gap: ${theme.spacing.s}px;
      padding: ${theme.spacing.xs}px;

      @container (min-width: ${theme.breakpoints.m}px) {
        padding: ${theme.spacing.m}px;
        .page_title {
          text-align: center;
        }
      }
    }

    .checkbox_container {
      display: flex;
      align-items: flex-start;
      column-gap: ${theme.spacing.xxxs}px;

      > div {
        padding: unset;
      }
    }

    .actions_container {
      display: flex;
      justify-content: center;
      gap: ${theme.spacing.s}px;
      @container (max-width: ${theme.breakpoints.m}px) {
        flex-direction: column-reverse;
      }
    }
  `}
`;

const SubscriptionView = () => {
  useFluidLayout();
  const app = useApp();
  const legalEntity = useLegalEntity();
  const navigate = useNavigate();
  const { subscriptionId: subscriptionIdInParams } =
    useParams<SubscriptionViewPathParams["ChangePlanPage"]>();

  const [subscriptionViewState, subscriptionViewDispatch] = useReducer(
    SubscriptionViewContext.reducer,
    SubscriptionViewContext.initialState
  );

  const subscription = legalEntity.subscriptions?.find(
    (sub) => sub.id === Number(subscriptionIdInParams)
  );

  useEffect(() => {
    subscriptionViewDispatch({
      type: SubscriptionViewContext.ActionType.SetCurrentSubscription,
      payload: subscription,
    });
  }, [subscription]);

  const _getSubsPlansAndLocalExtraData = useCallback(async () => {
    if (subscription?.family.slug) {
      const { localExtraData, plans } =
        await SubscriptionsService.getSubsPlansAndLocalExtraData(
          subscription.family.slug
        );

      // Save in context
      subscriptionViewDispatch({
        type: SubscriptionViewContext.ActionType.SetSubsLocalExtraData,
        payload: localExtraData,
      });
      subscriptionViewDispatch({
        type: SubscriptionViewContext.ActionType.SetPlans,
        payload: plans,
      });
    }
  }, [subscription?.family.slug]);

  const _getSubscriptions = useCallback(async () => {
    if (!app.subscriptions) {
      app.refreshSubscriptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.subscriptions]);

  useEffect(() => {
    _getSubscriptions();
  }, [_getSubscriptions]);

  useEffect(() => {
    _getSubsPlansAndLocalExtraData();
  }, [_getSubsPlansAndLocalExtraData]);

  if (!app.subscriptions) {
    return <LoadingPage />;
  }

  if (!subscription) {
    navigate("..", { replace: true, relative: "path" });
  }

  return (
    <SubscriptionViewContext.Context.Provider
      value={{
        state: subscriptionViewState,
        dispatch: subscriptionViewDispatch,
      }}
    >
      <PageContainer>
        <Outlet />
      </PageContainer>
    </SubscriptionViewContext.Context.Provider>
  );
};

export default SubscriptionView;
