import React from "react";
import { PaymentError, PaymentMethod, PaymentOption } from "services/payment";
import LSUser from "types/user";

type State = {
  cardHolderName: string | null;
  cardInputsValidity: boolean;
  isReadyToPay: boolean;
  paymentError: PaymentError | null;
  paymentMethod: PaymentMethod;
  paymentOption: PaymentOption;
  savedPaymentMethods: LSUser.PaymentMethodItem[] | null;
  selectedCard: LSUser.PaymentMethodItem | null;
};

const initialState: State = {
  cardHolderName: null,
  cardInputsValidity: false,
  isReadyToPay: false,
  paymentError: null,
  paymentMethod: PaymentMethod.Card,
  paymentOption: PaymentOption.NewPaymentMethod,
  savedPaymentMethods: null,
  selectedCard: null,
};

enum Action {
  SetCardHolderName = "SET_CARD_HOLDER_NAME",
  SetCardInputValidity = "SET_CARD_INPUT_VALIDITY",
  SetIsReadyToPay = "SET_IS_READY_TO_PAY",
  SetPaymentError = "SET_PAYMENT_ERROR",
  SetPaymentMethod = "SET_PAYMENT_METHOD",
  SetPaymentOption = "SET_PAYMENT_OPTION",
  SetSavedPaymentMethods = "SET_SAVED_PAYMENT_METHODS",
  SetSelectedCard = "SET_SELECTED_CARD",
}

type SetCardHolderNameAction = {
  type: Action.SetCardHolderName;
  payload: State["cardHolderName"];
};

type SetCardInputValidityAction = {
  type: Action.SetCardInputValidity;
  payload: State["cardInputsValidity"];
};

type SetIsReadyToPayAction = {
  type: Action.SetIsReadyToPay;
  payload: State["isReadyToPay"];
};

type SetPaymentErrorAction = {
  type: Action.SetPaymentError;
  payload: State["paymentError"];
};

type SetPaymentMethodAction = {
  type: Action.SetPaymentMethod;
  payload: State["paymentMethod"];
};

type SetPaymentOptionAction = {
  type: Action.SetPaymentOption;
  payload: State["paymentOption"];
};

type SetSavedPaymentMethodsAction = {
  type: Action.SetSavedPaymentMethods;
  payload: LSUser.PaymentMethodItem[];
};

type SetSelectedCardAction = {
  type: Action.SetSelectedCard;
  payload: State["selectedCard"];
};

type DispatchAction =
  | SetCardHolderNameAction
  | SetCardInputValidityAction
  | SetIsReadyToPayAction
  | SetPaymentErrorAction
  | SetPaymentMethodAction
  | SetPaymentOptionAction
  | SetSavedPaymentMethodsAction
  | SetSelectedCardAction;

const reducer = (state: State, action: DispatchAction): State => {
  switch (action.type) {
    case Action.SetCardHolderName:
      return {
        ...state,
        cardHolderName: action.payload,
      };
    case Action.SetCardInputValidity:
      return {
        ...state,
        cardInputsValidity: action.payload,
      };
    case Action.SetIsReadyToPay:
      return {
        ...state,
        isReadyToPay: action.payload,
      };
    case Action.SetPaymentError:
      return {
        ...state,
        paymentError: action.payload,
      };
    case Action.SetPaymentMethod:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case Action.SetPaymentOption:
      return {
        ...state,
        paymentOption: action.payload,
        // If user select `PaymentOption.NewPaymentMethod`, remove `selectedCard` if any
        selectedCard:
          action.payload === PaymentOption.NewPaymentMethod
            ? null
            : state.selectedCard,
      };
    case Action.SetSavedPaymentMethods: {
      /** As of Aug 2024, we only display and use the default payment method
       * @see https://app.clickup.com/t/86bzu96yr
       */
      const visibleSavedPaymentMethods = action.payload.filter(
        (method) => method.is_default_payment_method
      );
      const defaultPaymentMethod =
        visibleSavedPaymentMethods.find(
          (method) => method.is_default_payment_method
        ) || null;

      return {
        ...state,
        savedPaymentMethods: visibleSavedPaymentMethods,
        // Find and set default payment method
        selectedCard: defaultPaymentMethod,
        paymentOption: defaultPaymentMethod
          ? PaymentOption.UseSavedCard
          : PaymentOption.NewPaymentMethod,
      };
    }
    case Action.SetSelectedCard:
      return {
        ...state,
        selectedCard: action.payload,
        // These properties should go together
        paymentOption: PaymentOption.UseSavedCard,
        paymentMethod: PaymentMethod.Card,
      };
    default:
      throw new Error("[PaymentFormContext] unexpected action type");
  }
};

type ContextType = {
  state: State;
  dispatch: React.Dispatch<DispatchAction>;
};

const Context = React.createContext<ContextType>({
  state: initialState,
  dispatch: () => null,
});

const PaymentFormContext = {
  Action,
  Context,
  initialState,
  reducer,
};

export default PaymentFormContext;
