import axios from "axios";
import { CookiesUtils } from "utils";

const _computeAuthorizationHeaderValue = (accessToken?: string) =>
  accessToken ? `Bearer ${accessToken}` : undefined;

const webApiInstance = axios.create({
  baseURL: `${process.env.REACT_APP_WEB_APP_DOMAIN}/api`,
  headers: {
    Authorization: _computeAuthorizationHeaderValue(
      CookiesUtils.getAccessToken()
    ),
  },
});

export default webApiInstance;
