enum PathParam {
  SubscriptionId = "subscriptionId",
}

export const SubscriptionViewPath = {
  BasePath: `:${PathParam.SubscriptionId}`,
  ChangePlanPage: "change-plan",
  BenefitsComparisonPage: "benefits-comparison",
  TerminationPage: "termination",
};

type BasePathParams = {
  [PathParam.SubscriptionId]: string;
};

export type SubscriptionViewPathParams = {
  ChangePlanPage: BasePathParams;
  BenefitsComparisonPage: BasePathParams;
};
