import { PaymentMethod as StripePaymentMethod } from "@stripe/stripe-js";
import { Text } from "@yolaw/ui-kit-components";
import { AmericanExpressV3, MastercardV3, VisaV3 } from "@yolaw/ui-kit-icons";
import { SvgIconProps } from "@yolaw/ui-kit-icons/types/types/SvgIconProps";
import { useMemo } from "react";
import styled, { css } from "styled-components";

const CardPreview = styled.div`
  ${({ theme }) => css`
    --card-background-color: ${theme.colors.neutral.lightest};
    --placeholder-color: #b1b1b117;

    aspect-ratio: 3/2;
    border-radius: ${theme.borderRadius.s}px;
    box-shadow: ${theme.shadows.blue.small};
    background-color: var(--card-background-color);

    display: grid;
    grid-template-areas:
      "card-holder card-type"
      "card-number card-number"
      "card-expiry card-expiry";
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 2fr 1.5fr 1fr;

    max-width: 230px;

    > .card-holder,
    .card-type,
    .card-number,
    .card-expiry {
      align-content: center;
      padding-inline: ${theme.spacing.xs}px;
    }

    > .card-holder {
      grid-area: card-holder;
      padding-right: 0;
      .placeholder {
        display: inline-block;
        background-color: var(--placeholder-color);
        border-radius: 2px;
        width: 5em;
        height: 2em;
      }
    }
    > .card-type {
      grid-area: card-type;
      margin-left: auto;
    }
    > .card-number {
      grid-area: card-number;
      .placeholder {
        display: inline-block;
        --dot-bg: var(--card-background-color);
        --dot-color: var(--placeholder-color);
        --dot-size-w: 3em;
        --dot-gap: 0.5em;
        --dot-space: calc(var(--dot-size-w) + var(--dot-gap));
        width: calc(4 * var(--dot-space));
        height: 0.8em;
        background: linear-gradient(
              90deg,
              var(--dot-bg) calc(var(--dot-space) - var(--dot-size-w)),
              transparent 1%
            )
            left / var(--dot-space),
          var(--dot-color);
      }
    }
    > .card-expiry {
      grid-area: card-expiry;
      text-align: end;
      background-color: var(--placeholder-color);
    }

    position: relative;
    overflow: hidden;
    > .masks.top_right {
      position: absolute;
      left: 50%;
      bottom: 55%;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background: var(--placeholder-color);
    }
  `}
`;

const StyledText = styled(Text).attrs({
  fontWeightVariant: "bold",
  type: "XSMALL",
})``;

type BankCardInfoProps = {
  card: StripePaymentMethod.Card | undefined;
  cardHolderName: string | null;
};
const BankCardInfo = ({ card, cardHolderName }: BankCardInfoProps) => {
  const { brand, exp_month, exp_year, last4 } = card || {};

  const CardTypeIcon = useMemo(() => {
    const props: SvgIconProps = {
      size: "40px",
    };
    switch (brand) {
      case "amex":
        return <AmericanExpressV3 {...props} />;
      case "visa":
        return <VisaV3 {...props} />;
      case "mastercard":
        return <MastercardV3 {...props} />;
      default:
        return <></>;
    }
  }, [brand]);

  return (
    <CardPreview className="card_preview">
      <div className="masks top_right" />
      <div className="card-holder">
        <StyledText>
          {cardHolderName || <span className="placeholder" />}
        </StyledText>
      </div>
      <div className="card-type">{CardTypeIcon}</div>
      <div className="card-number">
        <StyledText>
          {last4 ? `XXXX XXXX XXXX ${last4}` : <span className="placeholder" />}
        </StyledText>
      </div>
      <div className="card-expiry">
        <StyledText>
          {exp_month && exp_year
            ? `${String(exp_month).padStart(2, "0")}/${String(exp_year).slice(
                -2
              )}`
            : ""}
        </StyledText>
      </div>
    </CardPreview>
  );
};

export default BankCardInfo;
