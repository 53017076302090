import styled, { css } from "styled-components";

const BenefitList = styled.ul`
  ${({ theme }) => css`
    &.benefits_list {
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing.xxxs}px;

      .benefit_item {
        display: flex;
        column-gap: ${theme.spacing.xxxs}px;
        align-items: baseline;

        > .icon {
          flex-shrink: 0;
        }
      }
    }

    &.benefits_list.align-self-center {
      align-self: center;
    }
  `}
`;

export default BenefitList;
