import { Button, Column, Tag, Text, Title } from "@yolaw/ui-kit-components";
import { useLegalEntity, useSegment } from "hooks";
import { PageContainer } from "pages/components/PageStyles";
import styled, { css } from "styled-components";

import FormalitiesContext, { FormalityGroup } from "contexts/formalities";
import FormalitiesSection from "pages/formalities/components/FormalitiesSection";
import FormalitiesListingPage from "pages/formalities/FormalitiesListingPage";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BuiltRoutePath, RoutePath } from "services/router";
import { LegalEntity } from "types/legal-entities";
import lscpPreview from "./assets/lscp-preview.png";
import lscpPreview2x from "./assets/lscp-preview@2x.png";
import lscpPreview3x from "./assets/lscp-preview@3x.png";

const StyledTag = styled(Tag).attrs({ size: "small" })``;

const StyledPageContainer = styled(PageContainer)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing.m}px;

    .page_header {
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing.xs}px;
      align-items: flex-start;

      @container (min-width: ${theme.breakpoints.m}px) {
        flex-direction: row;
        ${StyledTag} {
          align-self: center;
        }
      }
    }

    .sections {
      display: flex;
      flex-direction: column;
      row-gap: inherit;

      &.--company-registered {
        flex-direction: column-reverse;
      }
    }
  `}
`;

// prettier-ignore
const StyledSection = styled<React.ElementType>(Column).attrs({as: "section"})`
  ${({ theme }) => css`
    box-shadow: ${theme.shadows.blue.small};
    border-radius: ${theme.borderRadius.s}px;
    padding: ${theme.spacing.xs}px;
    row-gap: ${theme.spacing.s}px;

    &.section--compte_pro {
      .btn_compte_pro_access {
        align-self: stretch;
      }

      @container (min-width: ${theme.breakpoints.m}px) {
        .btn_compte_pro_access {
          align-self: center;
          flex-shrink: 0;
        }
      }
    }

    &.section--ongoing_formalities {
      .formality_card_container {
        box-shadow: unset;
        border: 1px solid ${theme.colors.neutral.lightest};
      }

      &.empty_list {
        justify-content: column;
      }
      .btn_formalities_access {
        align-self: stretch;
      }

      @container (min-width: ${theme.breakpoints.m}px) {
        &.empty_list {
          flex-direction: row;
          justify-content: space-between;
        }
        .btn_formalities_access {
          align-self: center;
        }
      }
    }
  `}
`;

const LSCPBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    row-gap: ${theme.spacing.xs}px;

    @container (min-width: ${theme.breakpoints.m}px) {
      flex-direction: row;
      padding: 0 ${theme.spacing.xs}px;

      &.column-gap-l {
        column-gap: ${theme.spacing.l}px;
      }
      &.column-gap-xs {
        column-gap: ${theme.spacing.xs}px;
      }
    }
  `}

  .img-lscp-preview {
    flex-shrink: 0;
    width: 266px;
    height: 122px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: image-set(
      url(${lscpPreview}) 1x,
      url(${lscpPreview2x}) 2x,
      url(${lscpPreview3x}) 3x
    );
  }

  .lscp-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

/** Based on the LE's SIREN & LE's compte_pro_active */
enum CompteProCompanyStatus {
  /** LE has SIREN && compte_pro_active */
  Active = "ACTIVE",
  /** LE has SIREN && !compte_pro_active */
  Inactive = "INACTIVE",
  /** LE has NO SIREN && !compte_pro_active */
  Waiting = "WAITING",
}

const getCompteProCompanyStatus = (le: LegalEntity) => {
  if (!!le.siren) {
    if (!!le.compte_pro_active) return CompteProCompanyStatus.Active;
    return CompteProCompanyStatus.Waiting;
  }
  return CompteProCompanyStatus.Inactive;
};

type CompteProSectionProps = {
  compteProSubscription: NonNullable<
    ReturnType<typeof useLegalEntity>["lsCompteProAccessibleSubscription"]
  >;
};
const CompteProSection = ({ compteProSubscription }: CompteProSectionProps) => {
  const segment = useSegment();
  const currentLegalEntity = useLegalEntity();

  const compteProCompanyStatus = getCompteProCompanyStatus(currentLegalEntity);
  const ssoURL =
    compteProSubscription.buildSSORedirectionURL(RoutePath.LSCPProAccount) ||
    "www.google.com";
  const isLEActiveAndHasSSOURL =
    compteProCompanyStatus === CompteProCompanyStatus.Active && !!ssoURL;

  const accessLSCP = () => {
    segment.track("ls compte pro cta: clicked", {
      le_id: currentLegalEntity.id,
      sub_ls_compte_pro_status: compteProSubscription.status,
    });

    window.open(ssoURL);
  };

  return (
    <StyledSection className="section--compte_pro">
      <Title type="H4">Mon Compte Pro tout-en-un</Title>

      <LSCPBlock
        className={isLEActiveAndHasSSOURL ? "column-gap-xs" : "column-gap-l"}
      >
        <span className="img-lscp-preview" />
        <div className="lscp-info">
          {compteProCompanyStatus === CompteProCompanyStatus.Active && (
            <>
              <Text fontWeightVariant="bold">
                Votre Compte Pro Legalstart est disponible&nbsp;!
              </Text>
              <Text>
                Payez, virez, encaissez : la solution tout-en-un la plus
                efficace pour gérer vos finances.
              </Text>
            </>
          )}
          {compteProCompanyStatus === CompteProCompanyStatus.Inactive && (
            <>
              <Text fontWeightVariant="bold">
                Votre Compte Pro Legalstart est bientôt prêt&nbsp;!
              </Text>
              <Text>
                Nous procéderons à sa création dès que votre société sera
                immatriculée.
              </Text>
            </>
          )}
          {compteProCompanyStatus === CompteProCompanyStatus.Waiting && (
            <>
              <Text fontWeightVariant="bold">
                Votre Compte Pro Legalstart est en cours de création
              </Text>
              <Text>
                Vous pourrez y accéder dès qu'il sera actif. Vous serez notifié
                par email.
              </Text>
            </>
          )}
        </div>
        {isLEActiveAndHasSSOURL && (
          <Button
            className="btn_compte_pro_access"
            size="small"
            onClick={accessLSCP}
          >
            Accéder à mon compte
          </Button>
        )}
      </LSCPBlock>
    </StyledSection>
  );
};

const OnGoingFormalitiesSection = () => {
  const navigate = useNavigate();
  const {
    state: { groups },
  } = useContext(FormalitiesContext.Context);

  const inProgressFormalities = groups[FormalityGroup.InProgress].formalities;

  const goToFormalitiesPage = () => {
    navigate(BuiltRoutePath.FormalitiesPage);
  };

  return (
    <StyledSection
      className={`section--ongoing_formalities ${
        inProgressFormalities.length ? "" : "empty_list"
      }`}
    >
      <div>
        <Title type="H4">Mes démarches en cours</Title>
        <Text>Les démarches payées et non finalisées sont affichées ici.</Text>
      </div>

      {/* In-progress Formalities */}
      <FormalitiesSection
        formalities={inProgressFormalities}
        ctaVariant="secondary"
      />

      <Button
        className="btn_formalities_access"
        variant="secondary"
        size="small"
        onClick={goToFormalitiesPage}
      >
        Afficher toutes mes démarches
      </Button>
    </StyledSection>
  );
};

const HomePageContent = () => {
  const segment = useSegment();
  const currentLegalEntity = useLegalEntity();

  // `x!` because we checked before rendering this component
  const accessibleLSCompteProSub =
    currentLegalEntity.lsCompteProAccessibleSubscription!;
  const hasCompanyRegistered = !!currentLegalEntity.siren;

  useEffect(() => {
    segment.track("userspace homepage: displayed", {
      le_id: currentLegalEntity.id,
      sub_ls_compte_pro_status: accessibleLSCompteProSub.status,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledPageContainer>
      <div className="page_header">
        <Title type={"H2"}>{currentLegalEntity.name}</Title>
        {hasCompanyRegistered ? (
          <StyledTag color="green">Société immatriculée</StyledTag>
        ) : (
          <StyledTag color="blue">Immatriculation en cours</StyledTag>
        )}
      </div>

      <div
        className={`sections ${
          hasCompanyRegistered ? "--company-registered" : ""
        }`}
      >
        <OnGoingFormalitiesSection />
        <CompteProSection compteProSubscription={accessibleLSCompteProSub} />
      </div>
    </StyledPageContainer>
  );
};

export const HomePage = () => {
  const currentLegalEntity = useLegalEntity();

  const accessibleLSCompteProSub =
    currentLegalEntity.lsCompteProAccessibleSubscription;

  // no LSCP sub > render <FormalitiesPage />
  if (!accessibleLSCompteProSub) {
    return <FormalitiesListingPage />;
  }

  return <HomePageContent />;
};
