import { CheckmarkFlat, CloseFlat } from "@yolaw/ui-kit-icons";
import { SvgIconProps } from "@yolaw/ui-kit-icons/types/types/SvgIconProps";
import { useTheme } from "styled-components";

export enum BenefitIconType {
  Positive,
  Negative,
}

type BenefitIconProps = {
  type: BenefitIconType;
};

const BenefitIcon = ({ type }: BenefitIconProps) => {
  const theme = useTheme();

  const props: SvgIconProps = {
    className: "benefit_item icon",
    size: "12",
  };

  switch (type) {
    case BenefitIconType.Negative:
      return <CloseFlat color={theme.colors.error.dark} {...props} />;
    case BenefitIconType.Positive:
    default:
      return <CheckmarkFlat color={theme.colors.primary.main} {...props} />;
  }
};

export default BenefitIcon;
