/** Format a given date string to FR locale */
const formatDate = (date?: string | null) => {
  if (!date) return "INVALID DATE";

  return new Date(date).toLocaleDateString("fr-FR");
};

/**
 * Calculates the number of months between a given date and the current date
 *
 * @param inputDate - A date
 * @param comparisonDate - Optional date to compare against (defaults to current date)
 * @returns Number of months difference
 */
function getMonthsDifference(
  inputDate: Date,
  comparisonDate: Date = new Date()
): number {
  // Calculate the difference in months & years
  const yearDiff = inputDate.getFullYear() - comparisonDate.getFullYear();
  const monthDiff = inputDate.getMonth() - comparisonDate.getMonth();

  // Calculate total months difference
  return yearDiff * 12 + monthDiff;
}

const DateTimeUtils = {
  formatDate,
  getMonthsDifference,
};

export default DateTimeUtils;
