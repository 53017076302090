import {
  Button,
  Checkbox,
  Column,
  Text,
  Title,
} from "@yolaw/ui-kit-components";
import Modal from "components/modal/Modal";
import { ModalName } from "contexts/app";
import { useApp, useIsMobile } from "hooks";
import { useContext, useState } from "react";
import { SubscriptionsService } from "services";
import { useTheme } from "styled-components";
import Subscription, {
  SubscriptionPlan,
  SubscriptionStatus,
  SubscriptionTier,
} from "types/subscriptions";
import DateTimeUtils from "utils/datetime";
import NumberUtils from "utils/number";
import TranslatorUtils from "utils/translator";
import SubscriptionViewContext from "../../SubscriptionViewContext";
import ChangePlanContext from "../ChangePlanContext";

type ContentByStatus = {
  title: string;
  content: JSX.Element;
};
const getContentByStatus = (
  subscription: Subscription | undefined,
  newPlan: SubscriptionPlan | undefined,
  newTier: SubscriptionTier | undefined
): ContentByStatus => {
  const defaultTitle = "Changement d’abonnement";

  switch (subscription?.status) {
    case SubscriptionStatus.Active:
      return {
        title: "Changer de plan ?",
        content: (
          <>
            <Text type="body">
              Le changement sera effectif à la fin du cycle en cours, soit le{" "}
              <strong>
                {DateTimeUtils.formatDate(subscription.current_period_end_date)}
              </strong>
              .
            </Text>
            <Text type="body">
              À partir de cette date vous serez prélevé(e){" "}
              <strong>
                de {NumberUtils.currencyFormat(newTier?.flat_price || 0)}
                &nbsp;HT soit{" "}
                {NumberUtils.currencyFormat(newTier?.total_price_it || 0)}
                &nbsp;TTC
              </strong>
              .
            </Text>
          </>
        ),
      };
    case SubscriptionStatus.Draft:
      return {
        title: defaultTitle,
        content: (
          <Text>
            Votre changement de formule sera bientôt activé. Cette nouvelle
            formule prendra effet au moment de la création de votre{" "}
            <strong>
              {subscription.family.display_name || subscription.family.name}
            </strong>
            . À partir de cette date, vous serez prélevé{" "}
            <strong>
              de {NumberUtils.currencyFormat(newTier?.total_price_it || 0)}
              &nbsp;TTC par{" "}
              {TranslatorUtils.translate(newPlan?.period_unit || "month")}.
            </strong>
          </Text>
        ),
      };
    case SubscriptionStatus.Trialing:
      return {
        title: defaultTitle,
        content: (
          <>
            <Text type="body">
              Vous bénéficiez actuellement de la période d'essai pour cet
              abonnement.
            </Text>
            <Text type="body">
              Votre changement de formule prendra effet immédiatement au
              lancement du prochain cycle le{" "}
              <strong>
                {DateTimeUtils.formatDate(subscription.trial_end_date)}
              </strong>
              .
            </Text>
            <Text type="body">
              Vous serez facturé{" "}
              <strong>
                de {NumberUtils.currencyFormat(newTier?.flat_price || 0)}
                &nbsp;HT soit{" "}
                {NumberUtils.currencyFormat(newTier?.total_price_it || 0)}
                &nbsp;TTC chaque{" "}
                {TranslatorUtils.translate(newPlan?.period_unit || "month")}
              </strong>
              , à cette date.
            </Text>
          </>
        ),
      };
    default:
      return {
        title: defaultTitle,
        content: <></>,
      };
  }
};

const DownsellConfirmationModal = () => {
  const app = useApp();
  const isMobile = useIsMobile();
  const theme = useTheme();

  const subscriptionViewContext = useContext(SubscriptionViewContext.Context);
  const changePlanContext = useContext(ChangePlanContext.Context);

  const { currentSubscription } = subscriptionViewContext.state;
  const { newPlan } = changePlanContext.state;
  const newTier = newPlan?.tiers[0];

  const [changeConfirmed, setChangeConfirmed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const closeModal = () => {
    app.closeModal();
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChangeConfirmed(event.target.checked);
  };

  const handleChangePlan = async () => {
    if (!currentSubscription || !newTier) return;
    setIsSubmitting(true);
    try {
      await SubscriptionsService.switchTier(currentSubscription.id, newTier.id);

      // Update subscriptions with latest info
      await app.refreshSubscriptions();

      app.openModal({
        name: ModalName.SubsMgmntSwitchTierSuccess,
        openedBy: {
          context: "change-plan-downsell",
          from: "successful-request",
        },
      });
    } catch (error: any) {
      setError(error.message || "Échec du changement de plan.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const { title, content } = getContentByStatus(
    currentSubscription,
    newPlan,
    newTier
  );

  return (
    <Modal isOpen={true} hideCloseButton onClose={closeModal}>
      <Column style={{ rowGap: `${theme.spacing.m}px` }}>
        <Column>
          <span style={{ textAlign: isMobile ? "left" : "center" }}>
            <Title type={isMobile ? "H1" : "H2"}>{title}</Title>
          </span>

          {content}
        </Column>

        <div className="checkbox_container">
          <Checkbox
            id="change-confirmation-downsell"
            onChange={handleCheckboxChange}
          />
          <label htmlFor="change-confirmation-downsell">
            Je confirme vouloir changer ma formule.
          </label>
        </div>

        {error && (
          <Text color="error.dark" fontWeightVariant="bold">
            {error}
          </Text>
        )}

        <div className="actions_container">
          <Button variant="secondary" onClick={closeModal}>
            Annuler
          </Button>
          <Button
            variant="primary"
            disabled={!changeConfirmed || isSubmitting}
            isLoading={isSubmitting}
            onClick={handleChangePlan}
          >
            Confirmer
          </Button>
        </div>
      </Column>
    </Modal>
  );
};

export default DownsellConfirmationModal;
