import { Button, Column, Text, Title } from "@yolaw/ui-kit-components";
import { FC } from "react";
import { useRouteError } from "react-router-dom";
import { styled } from "styled-components";

const PageContainer = styled<React.ElementType>(Column)`
  min-width: 100dvw;
  min-height: 100dvh;
  justify-content: center;
  text-align: center;
  padding: ${({ theme }) => theme.spacing.m}px;
`;

type RouteError = {
  statusText?: string;
  message?: string;
};

type ErrorPageProps = {
  customMessage?: string;
};

export const ErrorPage: FC<ErrorPageProps> = ({ customMessage }) => {
  const error = useRouteError() as RouteError;

  const errorMessage =
    customMessage || "Désolé, une erreur inattendue s'est produite.";

  const errorDetail = error?.statusText || error?.message;

  const backToHome = () => window.location.assign("/");

  return (
    <PageContainer>
      <Title type="H1" text="Oops !" />
      <Title type="H3">{errorMessage}</Title>

      {errorDetail && (
        <p>
          <Text>
            <i>{errorDetail}</i>
          </Text>
        </p>
      )}

      <Button variant="tertiary" icon="ChevronLeft" onClick={backToHome}>
        Retour à mon compte
      </Button>
    </PageContainer>
  );
};
