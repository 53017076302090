import { ReactNode } from "react";
import styled, { css } from "styled-components";
import { Column, Title } from "@yolaw/ui-kit-components";

const Container = styled<React.ElementType>(Column)`
  margin-inline: auto;

  ${({ theme }) => css`
    max-width: 507px;
    padding: ${theme.spacing.m}px;
    box-shadow: ${theme.shadows.blue.small};
    border-radius: ${theme.borderRadius.s}px;
  `}
`;

const Image = styled.img`
  max-height: 180px;
`;

const ContentWrapper = styled<React.ElementType>(Column)`
  ${({ theme }) => css`
    gap: ${theme.spacing.xxxs}px;
  `}
`;

type CapitalDepositPageWaitingCardProps = {
  title: string;
  children: ReactNode;
  imgSrc: string;
};

export const CapitalDepositPageWaitingCard = ({
  title,
  children,
  imgSrc,
}: CapitalDepositPageWaitingCardProps) => {
  return (
    <Container>
      <Image src={imgSrc} />
      <ContentWrapper>
        <Title type="H3" textAlign="center">
          {title}
        </Title>
        {children}
      </ContentWrapper>
    </Container>
  );
};
