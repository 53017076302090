import {
  Button,
  Column,
  displayToast,
  Hint,
  HintType,
  Text,
  Title,
} from "@yolaw/ui-kit-components";
import Modal from "components/modal/Modal";
import { ModalName } from "contexts/app";
import { useApp, useLegalEntity, useUser } from "hooks";
import { useState } from "react";
import { LegalEntityService, UserService } from "services";
import styled, { css } from "styled-components";
import { LSPaymentMethod } from "types";

const Container = styled<React.ElementType>(Column)`
  ${({ theme }) => css`
    row-gap: ${theme.spacing.s}px;
    text-align: center;

    .actions_container {
      display: flex;
      justify-content: space-between;
      gap: ${theme.spacing.xs}px;
    }

    @container (max-width: ${theme.breakpoints.s}px) {
      .actions_container {
        flex-direction: column-reverse;
      }
    }

    @container (min-width: ${theme.breakpoints.s}px) {
      width: 480px;
      margin: 0 auto ${theme.spacing.xs}px;
    }
  `}
`;

type Props = {
  setupFor: LSPaymentMethod.CardProcessorType;
};
const DetachDefaultPaymentMethodModal = ({ setupFor }: Props) => {
  const app = useApp();
  const legalEntity = useLegalEntity();
  const user = useUser();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const closeModal = () => {
    app.closeModal();
  };

  const setupForCompany =
    setupFor === LSPaymentMethod.CardProcessorType.Company;

  const paymentMethod = setupForCompany
    ? legalEntity.paymentMethod
    : user.paymentMethod;

  const detachPaymentMethod = async () => {
    try {
      setIsSubmitting(true);

      setupForCompany
        ? await LegalEntityService.detachDefaultPaymentMethod(legalEntity.id)
        : await UserService.detachDefaultPaymentMethod();

      // Update app context
      const updatedPMs = (app.paymentMethods || []).map((pm) =>
        pm.card_processor_type === setupFor
          ? { ...pm, payment_method: null }
          : pm
      );
      app.setPaymentMethods(updatedPMs);

      closeModal();

      displayToast({
        type: "success",
        content: `Votre moyen de paiement ${
          paymentMethod?.payment_method?.card?.last4
            ? `terminant par **** **** **** ${paymentMethod.payment_method.card.last4} `
            : ""
        }a bien été supprimé.`,
      });
    } catch (error: any) {
      setError(error.message || "");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      isOpen={app.openingModal?.name === ModalName.PaymentMethodDetach}
      onClose={closeModal}
    >
      <Container>
        <Title type={"H3"}>Supprimer le moyen de paiement</Title>

        <Text color="neutral.darker">
          Êtes-vous sûr(e) de vouloir supprimer ce moyen de paiement&nbsp;?
        </Text>

        {error && <Hint type={HintType.Error}>{error}</Hint>}

        <div className="actions_container">
          <Button
            variant="secondary"
            disabled={isSubmitting}
            onClick={closeModal}
          >
            Garder le moyen de paiement
          </Button>
          <Button
            onClick={detachPaymentMethod}
            disabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Supprimer
          </Button>
        </div>
      </Container>
    </Modal>
  );
};

export default DetachDefaultPaymentMethodModal;
