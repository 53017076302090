import ApiService from "services/api";


export const getWebSubscriptions = async () => {
  try {
    const response = await ApiService.webSubscriptions.getWebSubscriptions();
    return response?.data || [];
  } catch (error) {
    throw error;
  }
};
