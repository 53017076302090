import { Title } from "@yolaw/ui-kit-components";
import { TextButtonProps } from "@yolaw/ui-kit-components/types/ui-kit-components/src/components/button";
import SeeMoreToggleButton from "pages/components/SeeMoreToggleButton";
import React, { useState } from "react";
import styled from "styled-components";
import { Formality } from "types/formalities";
import { LIST_MAX_VISIBLE_ELEMENTS } from "utils/constants";
import FormalityCard from "./FormalityCard";

const Section = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: ${(props) => props.theme.spacing.xs}px;

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    row-gap: ${(props) => props.theme.spacing.xxxs}px;
  }
`;

const FormalitiesList = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: ${(props) => props.theme.spacing.xxxs}px;
  list-style: none;
`;

type FormalitiesSectionProps = {
  title?: string;
  formalities: Formality[];
  ctaVariant?: TextButtonProps["variant"];
};

const getCollapsedFormalities = (formalities: Formality[]) => {
  const displayedFormalities = [...formalities].slice(
    0,
    LIST_MAX_VISIBLE_ELEMENTS
  );
  return displayedFormalities;
};

const FormalitiesSection: React.FC<FormalitiesSectionProps> = ({
  title,
  formalities,
  ctaVariant,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const displayedFormalities = isOpen
    ? formalities
    : getCollapsedFormalities(formalities);

  const formalitiesCount = formalities.length;

  const onSeeMoreToggleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  if (formalitiesCount > 0) {
    const sectionTitle = title && `${title} (${formalitiesCount})`;

    return (
      <Section>
        {sectionTitle && <Title text={sectionTitle} type="h4" />}
        <FormalitiesList>
          {displayedFormalities.map((formality) => (
            <li key={`${formality.source_id}-${formality.title}`}>
              <FormalityCard formality={formality} ctaVariant={ctaVariant} />
            </li>
          ))}
        </FormalitiesList>
        {formalitiesCount > LIST_MAX_VISIBLE_ELEMENTS && (
          <SeeMoreToggleButton
            isOpen={isOpen}
            onClick={onSeeMoreToggleButtonClick}
          >
            {isOpen ? "Voir moins" : "Voir plus"}
          </SeeMoreToggleButton>
        )}
      </Section>
    );
  }

  return null;
};

export default FormalitiesSection;
