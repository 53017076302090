import { Button } from "@yolaw/ui-kit-components";
import { FC, useContext, useRef, useState } from "react";
import { css, styled } from "styled-components";

import { LayoutContext } from "contexts";
import { useApp, useIsMobile, useOutsideClick } from "hooks";
import LegalEntityMenuButton from "./LegalEntityMenuButton";
import LegalEntitySwitchHeader from "./LegalEntitySwitchHeader";

const Container = styled.div<{ $maxLENameLength: number }>`
  position: relative;
  ${({ theme, $maxLENameLength }) => css`
    @media (max-width: ${theme.breakpoints.l}px) {
      width: 100%;
    }
    @media (min-width: ${theme.breakpoints.l}px) {
      min-width: ${$maxLENameLength}em;
    }
    @media (min-width: ${theme.breakpoints.l}px) and (max-width: ${theme
        .breakpoints.xl}px) {
      max-width: 36em;
    }
    @media (min-width: ${theme.breakpoints.xl}px) {
      max-width: 48em;
    }
  `}
`;

const LegalEntityMenu = styled.div`
  position: absolute;
  top: calc(100% - 1px);
  width: 100%;
  z-index: 1;
  ${({ theme }) => css`
    padding-inline: ${theme.spacing.xxxs}px;
    border: 1px solid ${theme.colors.neutral.lighter};
    border-top: 0;
    background-color: ${theme.colors.common.white};
    border-bottom-left-radius: ${theme.borderRadius.s}px;
    border-bottom-right-radius: ${theme.borderRadius.s}px;
  `};
`;

const LegalEntityMenuList = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: ${(props) => props.theme.spacing.xxxs}px;
  list-style: none;
  margin-bottom: ${(props) => props.theme.spacing.xxxs}px;
`;

const LegalEntityFooter = styled.div`
  ${({ theme }) => css`
    padding-top: ${theme.spacing.xxxs}px;
    border-top: 1px solid ${theme.colors.neutral.lighter};
  `};
`;

const CreateCompanyButton = styled(Button)`
  width: 100%;
  > div {
    justify-content: flex-start;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

const LegalEntitySwitch: FC = () => {
  const app = useApp();
  const isMobile = useIsMobile();
  const { legalEntities } = app;
  const layoutContext = useContext(LayoutContext.Context);

  const [isOpen, setIsOpen] = useState(false);
  const legalEntitySwitchContainerRef = useRef<HTMLDivElement | null>(null);

  const closeLegalEntitySwitch = () => {
    setIsOpen(false);
  };

  useOutsideClick(legalEntitySwitchContainerRef, closeLegalEntitySwitch);

  if (!layoutContext.state.leSelectorVisible) {
    return null;
  }

  const onSwitchHeaderClick = () => setIsOpen((prevState) => !prevState);

  const onCreateCompanyButtonClick = () =>
    window.location.assign(
      process.env.REACT_APP_LEGALSTART_CMS_URL + "/creation-entreprise"
    );

  if (legalEntities?.length && app.currentLegalEntityId) {
    const otherLegalEntities = legalEntities.filter(
      (legalEntity) => legalEntity.id !== app.currentLegalEntityId
    );

    const maxLENameLength = Math.max(
      /** It's unexpected but sometime the `le.name` can be `null` due to
       * the BE data processing => wrap `String(le.name)` for safety purposes.
       */
      ...legalEntities.map((le) => String(le.name).length)
    );

    return (
      <Container
        ref={legalEntitySwitchContainerRef}
        $maxLENameLength={maxLENameLength}
      >
        <LegalEntitySwitchHeader
          $isOpen={isOpen}
          onClick={onSwitchHeaderClick}
        />
        {isOpen && (
          <LegalEntityMenu>
            {otherLegalEntities.length ? (
              <LegalEntityMenuList>
                {otherLegalEntities.map((legalEntity) => (
                  <li key={legalEntity.id}>
                    <LegalEntityMenuButton
                      legalEntity={legalEntity}
                      onClickCallback={closeLegalEntitySwitch}
                    />
                  </li>
                ))}
              </LegalEntityMenuList>
            ) : null}
            <LegalEntityFooter>
              <CreateCompanyButton
                variant="tertiary"
                icon="PlusCircleFlatV3"
                onClick={onCreateCompanyButtonClick}
              >
                Créer une entreprise
              </CreateCompanyButton>
            </LegalEntityFooter>
          </LegalEntityMenu>
        )}
      </Container>
    );
  }

  return (
    <Button
      type="button"
      variant="primary"
      onClick={onCreateCompanyButtonClick}
      // FIXME: isMobile isn't changed on resizing
      size={isMobile ? "large" : "small"}
    >
      Créer une entreprise
    </Button>
  );
};

export default LegalEntitySwitch;
