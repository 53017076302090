import {
  Accent,
  Column,
  Heading,
  Hint,
  HintType,
  Label,
  Row,
  Text,
  Title,
} from "@yolaw/ui-kit-components";
import { LockFlat } from "@yolaw/ui-kit-icons";
import PaymentFormContext from "components/payment-form/PaymentFormContext";
import PaymentMethodIcons from "components/payment-form/PaymentMethodIcons";
import PaymentMethodSelector from "components/payment-form/PaymentMethodSelector";
import React, { useEffect, useReducer } from "react";
import { UserService } from "services";
import styled, { useTheme } from "styled-components";
import { TOC_LINKS } from "utils/constants";
import NumberUtils from "utils/number";
import TranslatorUtils from "utils/translator";
import { usePaymentModalContext } from "../../hooks";
import PayButton from "./PayButton";

const StyledHeading = styled(Heading)`
  margin: 0;
`;

const PayWithContainer = styled<React.ElementType>(Row)`
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.m}px) {
    align-items: flex-start;
    flex-direction: column;
    row-gap: 0;
  }
`;

const StyledLabel = styled(Label)`
  text-align: center;
  svg {
    display: initial;
  }
`;

const PadlockIcon = styled(LockFlat)`
  vertical-align: top;
`;

const TOSLink = styled.a`
  color: ${({ theme }) => theme.colors.neutral.light};
`;

const StyledErrorBox = styled(Hint)`
  text-align: left;
`;

const PayButtonContainer = styled.div`
  text-align: center;
  margin: auto;

  button {
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    width: 50%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.m}px) {
    width: 100%;
  }
`;

const PaymentPage = () => {
  const theme = useTheme();
  const paymentModal = usePaymentModalContext();
  const { subscriptionDisplayName, styles } = paymentModal.state.modalConfigs;

  const [paymentFormState, paymentFormDispatch] = useReducer(
    PaymentFormContext.reducer,
    PaymentFormContext.initialState
  );

  const getSavedPaymentMethods = async () => {
    const savedPaymentMethods = await UserService.getPaymentMethods();
    paymentFormDispatch({
      type: PaymentFormContext.Action.SetSavedPaymentMethods,
      payload: savedPaymentMethods,
    });
  };

  useEffect(() => {
    if (!paymentFormState.savedPaymentMethods) {
      // Get saved payment method if it's not there
      getSavedPaymentMethods();
    }
  }, [paymentFormState.savedPaymentMethods]);

  return (
    <PaymentFormContext.Context.Provider
      value={{ state: paymentFormState, dispatch: paymentFormDispatch }}
    >
      <Title type="H3">
        M&apos;abonner à{" "}
        <Accent color={styles.color}>{subscriptionDisplayName}</Accent>
      </Title>

      <Column style={{ rowGap: `${theme.spacing.s}px` }}>
        <PayWithContainer>
          <StyledHeading type="H4">Payer avec</StyledHeading>
          <PaymentMethodIcons />
        </PayWithContainer>

        <StyledLabel fontWeightVariant="bold">
          <PadlockIcon /> SSL Secure Connection
        </StyledLabel>

        <PaymentMethodSelector />

        <Column>
          {paymentFormState.paymentError && (
            <StyledErrorBox
              type={HintType.Error}
              description="Veuillez recommencer ou utiliser un autre moyen de paiement. Si le problème persiste, veuillez contacter notre service d'assistance !"
            >
              {paymentFormState.paymentError.message ||
                "Il semblerait que votre paiement ai échoué"}
            </StyledErrorBox>
          )}

          <Text type="small" color="neutral.dark" textAlign="left">
            En cliquant sur &quot;M&apos;abonner à {subscriptionDisplayName}
            &quot; vous attestez avoir lu et accepté les{" "}
            <TOSLink
              href={TOC_LINKS.CGU}
              target="_blank"
              rel="noopener noreferrer"
            >
              Conditions Générales
            </TOSLink>{" "}
            et la{" "}
            <TOSLink
              href={TOC_LINKS.PRIVACY}
              target="_blank"
              rel="noopener noreferrer"
            >
              charte de la vie privée
            </TOSLink>
            .
          </Text>

          <Text type="BODY" fontWeightVariant="bold">
            {NumberUtils.currencyFormat(
              paymentModal.selectedPlan?.price_et || 0
            )}
            &nbsp;
            <sup>HT</sup>&nbsp;/
            {TranslatorUtils.translate(
              String(paymentModal.selectedPlan?.period_unit)
            )}
            , soit{" "}
            {NumberUtils.currencyFormat(
              paymentModal.selectedPlan?.price_it || 0
            )}
            &nbsp;
            <sup>TTC</sup>&nbsp;/
            {TranslatorUtils.translate(
              String(paymentModal.selectedPlan?.period_unit)
            )}
          </Text>

          <PayButtonContainer>
            <PayButton />
          </PayButtonContainer>
        </Column>
      </Column>
    </PaymentFormContext.Context.Provider>
  );
};

export default React.memo(PaymentPage);
