export enum FormalitySourceType {
  Funnel = "funnel",
  WebWorkflow = "web workflow",
  Service = "service",
}

export enum FormalityStatus {
  Cancelled = "cancelled",
  Done = "done",
  Draft = "draft",
  InProgress = "in_progress",
  WireTransferFrozen = "frozen_wire_transfer",
  WireTransferPending = "pending_wire_transfer",
}

interface FormalityBase {
  source_type: FormalitySourceType;
  source_id: number;
  legal_entity_id: number | null;
  detail_url: string;
  updated_at: Date;
  canceled_at?: Date | null;
  title: string;
  status: FormalityStatus;
}

interface FunnelFormality extends FormalityBase {
  source_type: FormalitySourceType.Funnel;
}

interface ServiceFormality extends FormalityBase {
  source_type: FormalitySourceType.Service;
}

interface WebWorkflowFormality extends FormalityBase {
  source_type: FormalitySourceType.WebWorkflow;
}

export type Formality =
  | FunnelFormality
  | ServiceFormality
  | WebWorkflowFormality;

export interface FunnelWireTransferDetails {
  invoice_reference: string;
  currency: string;
  total_amount: number;
  paid_amount: number;
  remaining_amount: number;
  wire_transfer_reference: string;
  financial_addresses: [
    {
      iban: {
        bic: string;
        iban: string;
        country: string;
        bank_address: {
          city: string;
          line1: string;
          line2: null;
          state: string;
          country: string;
          postal_code: string;
        };
        account_holder_name: string;
        account_holder_address: {
          city: string;
          line1: string;
          line2: null;
          state: string;
          country: string;
          postal_code: string;
        };
      };
      type: string;
      supported_networks: string[];
    }
  ];
}
