import { createContext } from "react";
import { SubscriptionPlan } from "types/subscriptions";

export enum ChangePlanType {
  Down = "DOWN",
  Up = "Up",
}

type State = {
  downOrUp?: ChangePlanType;
  newPlan?: SubscriptionPlan;
};

const initialState: State = {};

enum ActionType {
  SetData,
}

type DispatchAction = {
  type: ActionType.SetData;
  payload: State;
};

const reducer = (state: State, action: DispatchAction): State => {
  switch (action.type) {
    case ActionType.SetData:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

type ContextType = {
  state: State;
  dispatch: React.Dispatch<DispatchAction>;
};

const Context = createContext<ContextType>({
  state: initialState,
  dispatch: () => null,
});

const ChangePlanContext = {
  ActionType,
  Context,
  initialState,
  reducer,
};

export default ChangePlanContext;
