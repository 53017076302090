import { Button, Text, Title } from "@yolaw/ui-kit-components";
import { ModalName } from "contexts/app";
import { useApp } from "hooks";
import { BackButton } from "pages/components";
import BenefitList from "pages/components/subscriptions/BenefitList";
import { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import BenefitIcon, {
  BenefitIconType,
} from "services/subscriptions/conf/utils/BenefitIcon";
import {
  computeBenefitLabel,
  findProductAvailability,
  getCountDiff,
} from "services/subscriptions/conf/utils/product-benefits";
import styled, { css } from "styled-components";
import SubscriptionViewContext from "../../SubscriptionViewContext";
import ChangePlanContext from "../ChangePlanContext";

const ComparisonContainer = styled.div`
  max-width: 640px;

  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    color: ${theme.colors.neutral.darker};
    row-gap: ${theme.spacing.s}px;
  `}
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  ${({ theme }) => css`
    gap: ${theme.spacing.s}px;
    @container (max-width: ${theme.breakpoints.m}px) {
      flex-direction: column-reverse;
    }
  `}
`;

const BenefitsComparisonPage = () => {
  const app = useApp();
  const navigate = useNavigate();
  const changePlanContext = useContext(ChangePlanContext.Context);
  const subscriptionViewContext = useContext(SubscriptionViewContext.Context);

  const { newPlan } = changePlanContext.state;
  const { currentPlan, subsLocalExtraData } = subscriptionViewContext.state;

  const backToPlanSelectionPage = () => {
    navigate("..", { replace: true });
  };

  if (!currentPlan || !newPlan) {
    backToPlanSelectionPage();
  }

  const confirmDownsell = () => {
    app.openModal({
      name: ModalName.SubsMgmntDownsellConfirmation,
      openedBy: {
        context: "change-plan",
        from: "plan-item",
      },
    });
  };

  const diffs = useMemo(() => {
    const benefits = subsLocalExtraData?.benefitGroups.flatMap((g) => g.items);
    const currentBenefits = benefits?.filter(
      (b) =>
        currentPlan &&
        findProductAvailability(
          b.productAvailabilities,
          currentPlan.product.slug
        ) &&
        b
    );
    const newBenefits = benefits?.filter(
      (b) =>
        newPlan &&
        findProductAvailability(b.productAvailabilities, newPlan.product.slug)
    );
    return [currentBenefits, newBenefits].reduce((currentB, newB) =>
      currentB?.filter(
        (c) =>
          !newB?.includes(c) ||
          (typeof c.productAvailabilities[0] === "object" &&
            typeof c.productAvailabilities[1] === "object" &&
            c.productAvailabilities[0].count !==
              c.productAvailabilities[1].count)
      )
    );
  }, [currentPlan, newPlan, subsLocalExtraData?.benefitGroups]);

  return (
    <>
      <BackButton replace />

      <div className="content-container">
        <div className="page_title">
          <Title type="H2">
            Êtes-vous vous sûr(e) de vouloir changer de formule&nbsp;?
          </Title>
        </div>

        <ComparisonContainer>
          <Text>
            En passant de la formule{" "}
            <strong>{currentPlan?.product.name}</strong> à{" "}
            <strong>{newPlan?.product.name}</strong>, vous ne pourrez plus
            utiliser ces fonctionnalités importantes&nbsp;:
          </Text>

          <BenefitList className="benefits_list align-self-center">
            {diffs?.map((benefit) => (
              <li key={benefit.label} className="benefit_item">
                <BenefitIcon type={BenefitIconType.Negative} />
                <Text type="small" color="neutral.dark">
                  {computeBenefitLabel(
                    benefit.labelDownselling ||
                      benefit.labelLosing ||
                      benefit.label,
                    getCountDiff(
                      findProductAvailability(
                        benefit.productAvailabilities,
                        currentPlan?.product.slug!
                      ),
                      findProductAvailability(
                        benefit.productAvailabilities,
                        newPlan?.product.slug!
                      )
                    )
                  )}
                </Text>
              </li>
            ))}
          </BenefitList>
        </ComparisonContainer>

        <ActionsContainer>
          <Button variant="secondary" onClick={backToPlanSelectionPage}>
            Annuler
          </Button>
          <Button onClick={confirmDownsell}>Confirmer</Button>
        </ActionsContainer>
      </div>
    </>
  );
};

export default BenefitsComparisonPage;
