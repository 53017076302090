import { useApp } from "hooks";
import useAppRoutes from "hooks/useAppRoutes";
import { LoadingPage } from "pages/loading";
import { useEffect } from "react";
import {
  generatePath,
  matchPath,
  Navigate,
  Outlet,
  useNavigate,
} from "react-router-dom";
import { LegalEntity } from "types/legal-entities";

export enum RoutePath {
  HomePage = "home",
  FormalitiesPage = "formalities",
  ComptastartPage = "comptastart",
  ContactFormalistPage = "formality",
  ContactLawyerPage = "aj",
  ContactTechnicalSupportPage = "technical",
  InvoicesPage = "invoices",
  LegalAssistancePage = "assistance",
  LSComptaBasicPage = "ls-compta-basic",
  OtherServicesPage = "other-services",
  SubscriptionsManagementPage = "subscriptions-management",
  UserProfilePage = "profile",
  PaymentMethodsPage = "payment-methods",

  // LS Compte Pro
  LSCPCapitalDeposit = "capital_deposit",
  LSCPProAccount = "pro_account",
  LSCPMyExpenses = "my_expenses",
  LSCPMyLatestTransactions = "clients/transactions",
  LSCPGenerateInvoice = "clients/customer_invoices",

  // Authentication
  AuthenticationErrorPage = "authentication-error",
  LoginPage = "login",
  PasswordResetCollectEmailPage = "reset-password-email",
  PasswordResetPage = "reset-password",
  PasswordResetRequestPage = "reset-password-request",
  SetPasswordPage = "set-password",
  EmailModificationPage = "email-modification",
  EmailVerificationPage = "email-verification",
  SignUpPage = "sign-up",

  // Change email flow
  UserEmailChangeInitiateRequestSentPage = "change-email-requested",
  UserEmailChangeConfirmPage = "change-email-confirm",
  UserEmailChangeConfirmRequestSentPage = "change-email-confirmed",

  // LegalEntity
  LegalEntity = "le",

  // Registries
  Registries = "registres",
  MandatoryRecordsPage = "decisions",
  TitlesRecordsPage = "titres",

  // Zen
  Zen = "zen",
  LegalsObligations = "obligations-legales",
  AdministrativeDashboard = "dashboard-administratif",
}

export enum PathParams {
  LegalEntityId = "legalEntityId",
}

export const PathTemplate = {
  LegalEntityBase: `${RoutePath.LegalEntity}/:${PathParams.LegalEntityId}`,
  ZenApp: `${RoutePath.LegalEntity}/:${PathParams.LegalEntityId}/${RoutePath.Zen}`,
  RegistriesApp: `${RoutePath.LegalEntity}/:${PathParams.LegalEntityId}/${RoutePath.Registries}`,
};

/** Full page path based on the App's router structure */
export const BuiltRoutePath = {
  HomePage: `/${RoutePath.HomePage}`,
  FormalitiesPage: `/${RoutePath.FormalitiesPage}`,
  InvoicesPage: `/${RoutePath.InvoicesPage}`,
  LegalAssistancePage: `/${RoutePath.LegalAssistancePage}`,
  OtherServicesPage: `/${RoutePath.OtherServicesPage}`,
  PaymentMethodsPage: `/${RoutePath.PaymentMethodsPage}`,
  SubscriptionsManagementPage: `/${RoutePath.SubscriptionsManagementPage}`,
  UserProfilePage: `/${RoutePath.UserProfilePage}`,

  // Authentication pages
  LoginPage: `/${RoutePath.LoginPage}`,
  PasswordResetCollectEmailPage: `/${RoutePath.PasswordResetCollectEmailPage}`,
  PasswordResetRequestPage: `/${RoutePath.PasswordResetRequestPage}`,
  EmailModificationPage: `/${RoutePath.EmailModificationPage}`,
  EmailVerificationPage: `/${RoutePath.EmailVerificationPage}`,
  SignUpPage: `/${RoutePath.SignUpPage}`,

  // Change email flow
  UserEmailChangeInitiateRequestSentPage: `/${RoutePath.UserProfilePage}/${RoutePath.UserEmailChangeInitiateRequestSentPage}`,
  UserEmailChangeConfirmPage: `/${RoutePath.UserProfilePage}/${RoutePath.UserEmailChangeConfirmPage}`,
  UserEmailChangeConfirmRequestSentPage: `/${RoutePath.UserProfilePage}/${RoutePath.UserEmailChangeConfirmRequestSentPage}`,

  // Assistance sub pages
  ContactFormalistPage: `/${RoutePath.LegalAssistancePage}/${RoutePath.ContactFormalistPage}`,
  ContactLawyerPage: `/${RoutePath.LegalAssistancePage}/${RoutePath.ContactLawyerPage}`,
  ContactTechnicalSupportPage: `/${RoutePath.LegalAssistancePage}/${RoutePath.ContactTechnicalSupportPage}`,

  // LE centric pages
  ComptastartPage: `/${PathTemplate.LegalEntityBase}/${RoutePath.ComptastartPage}`,
  LSComptaBasicPage: `/${PathTemplate.LegalEntityBase}/${RoutePath.LSComptaBasicPage}`,
  // > LS Compte Pro
  LSCPCapitalDepositPage: `/${PathTemplate.LegalEntityBase}/${RoutePath.LSCPCapitalDeposit}`,
  LSCPProAccountPage: `/${PathTemplate.LegalEntityBase}/${RoutePath.LSCPProAccount}`,
  LSCPMyExpensesPage: `/${PathTemplate.LegalEntityBase}/${RoutePath.LSCPMyExpenses}`,
  LSCPMyLatestTransactionsPage: `/${PathTemplate.LegalEntityBase}/${RoutePath.LSCPMyLatestTransactions}`,
  LSCPGenerateInvoicePage: `/${PathTemplate.LegalEntityBase}/${RoutePath.LSCPGenerateInvoice}`,
  // Payment methods for LE
  PaymentMethodsForLEPage: `/${PathTemplate.LegalEntityBase}/${RoutePath.PaymentMethodsPage}`,
  /** Subscriptions management for LE */
  SubscriptionsManagementForLEPage: `/${PathTemplate.LegalEntityBase}/${RoutePath.SubscriptionsManagementPage}`,

  // - Zen sub pages
  LegalsObligationsPage: `/${PathTemplate.ZenApp}/${RoutePath.LegalsObligations}`,
  AdministrativeDashboardPage: `/${PathTemplate.ZenApp}/${RoutePath.AdministrativeDashboard}`,
  MandatoryRecordsPage: `/${PathTemplate.RegistriesApp}/${RoutePath.MandatoryRecordsPage}`,
  TitlesRecordsPage: `/${PathTemplate.RegistriesApp}/${RoutePath.TitlesRecordsPage}`,
};

export type DecisionViewPageParams = {
  decisionId: string;
};

export const SearchParams = {
  Common: {
    accessToken: "access_token",
    next: "next",
    source: "source" as "login" | "signup" | "lafoy-post-payment",
  },
  EmailVerificationPage: {
    newEmail: "new_email",
  },
  LoginPage: {
    email: "email",
  },
  PasswordResetPage: {
    token: "token",
  },
};

export const getMatchedLegalEntityBasePath = () =>
  matchPath(
    { path: PathTemplate.LegalEntityBase, end: false },
    window.location.pathname
  );

export const getLegalEntityIdInURL = () =>
  getMatchedLegalEntityBasePath()?.params[PathParams.LegalEntityId];

export const replaceLegalEntityInCurrentPath = (
  newLegalEntityId: LegalEntity["id"]
) => {
  const matchedLegalEntityBasePath = getMatchedLegalEntityBasePath();

  // Do nothing if the path doesn't match
  if (!matchedLegalEntityBasePath) return null;

  const { params, pattern, pathname } = matchedLegalEntityBasePath;

  const newPathPattern = window.location.pathname.replace(
    pathname,
    pattern.path
  );
  const newPath = generatePath(newPathPattern, {
    ...params,
    [PathParams.LegalEntityId]: newLegalEntityId,
  });

  // Updated URL
  return newPath;
};

/** For backward compatible purpose.
 * Redirect from non-LE to LE-included path for LE centric pages
 */
export const NavigateToLegalEntityBasePath = () => {
  const { generateAppPath } = useAppRoutes();

  const originPath = window.location.pathname;
  const legalEntityBasePath = generateAppPath(
    `/${PathTemplate.LegalEntityBase}${originPath}`
  );

  const matchedBase = matchPath(
    { path: PathTemplate.LegalEntityBase, end: false },
    legalEntityBasePath
  );

  /** The LE is not ready */
  if (Number.isNaN(Number(matchedBase?.params[PathParams.LegalEntityId])))
    return <LoadingPage />;

  return <Navigate to={legalEntityBasePath} replace />;
};

/** Assure that user owns the LE with the ID specified in the URL */
export const LegalEntityOwnershipChecker = () => {
  const app = useApp();
  const navigate = useNavigate();
  const legalEntityIdInURL = getLegalEntityIdInURL();

  useEffect(() => {
    if (
      app.legalEntities &&
      legalEntityIdInURL &&
      String(app.currentLegalEntityId) !== legalEntityIdInURL
    ) {
      // LE ID specified in the initial URL is not owned by the user
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.legalEntities]);

  if (!app.legalEntities) return <LoadingPage />;

  return <Outlet />;
};
