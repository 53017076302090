import { Navigate, Route, Routes } from "react-router-dom";
import FormalitiesListingPage from "./FormalitiesListingPage";
import { FunnelWireTransferDetailsPage } from "./funnel-view/wire-transfer-details";
import { RoutePath } from "./funnel-view/router";

const FormalitiesRoutes = () => {
  return (
    <Routes>
      <Route index element={<FormalitiesListingPage />} />
      <Route
        path={RoutePath.FunnelWireTransferDetailsPage}
        element={<FunnelWireTransferDetailsPage />}
      />
      <Route path="*" element={<Navigate to={"."} replace />} />
    </Routes>
  );
};

export default FormalitiesRoutes;
