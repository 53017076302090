import { PaymentMethod as StripePaymentMethod } from "@stripe/stripe-js";
import { Column, Text, Title } from "@yolaw/ui-kit-components";

type SEPADebitInfoProps = {
  sepaDebit: StripePaymentMethod.SepaDebit | undefined;
};
const SEPADebitInfo = ({ sepaDebit }: SEPADebitInfoProps) => {
  const { country, last4 } = sepaDebit || {};
  return (
    <Column>
      <Title type="H4">Prélèvement actuel : SEPA</Title>
      <Text>
        {country} ●●●●● {last4}
      </Text>
    </Column>
  );
};

export default SEPADebitInfo;
