import {
  Button,
  Column,
  displayToast,
  Select,
  Text,
  TextArea,
  Title,
} from "@yolaw/ui-kit-components";
import ButtonContactPhone from "components/ButtonContactPhone";
import {
  Fieldset,
  FormFooter,
  FormSection,
  FormSectionBody,
} from "components/FormStyles";
import UserInformationFieldset, {
  TUserInformationInputs,
} from "components/UserInformationFieldset";
import { AppContext, FormalitiesContext } from "contexts";
import { useSegment } from "hooks";
import { PageContentHeader } from "pages/components";
import { PageContentBody } from "pages/components/PageStyles";
import React, { useContext, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Form, useNavigate } from "react-router-dom";
import { AssistanceService } from "services";
import { BuiltRoutePath } from "services/router";
import styled, { css } from "styled-components";
import {
  AssistanceQuestionTheme,
  AssistanceQuestionType,
  AssistanceTechnicalContactFormData,
} from "types/assistance";
import { FormalitySourceType } from "types/formalities";
import { FORM_ERROR_MESSAGES } from "utils/form";

const trackingEventProperties = {
  "contact form": "technical assistance",
};

const SET_VALUE_OPTIONS = {
  shouldValidate: true,
  shouldDirty: true,
  shouldTouch: true,
};

const SectionHeader = styled<React.ElementType>(Column)`
  ${({ theme }) => css`
    && {
      row-gap: ${theme.spacing.xxxs}px;
    }
  `}
`;

enum InputName {
  formality = "formality",
  question = "question",
  questionThemeId = "questionThemeId",
}

type FormInputs = TUserInformationInputs & {
  [InputName.formality]: string;
  [InputName.question]: string;
  [InputName.questionThemeId]: string;
};

const ContactTechnicalSupportPage = () => {
  const segment = useSegment();
  const navigate = useNavigate();
  const { state: appState } = useContext(AppContext.Context);
  const { state: formalitiesState } = useContext(FormalitiesContext.Context);
  const { user } = appState;

  const [questionThemes, setQuestionThemes] = useState<
    AssistanceQuestionTheme[]
  >([]);

  const formMethods = useForm<FormInputs>({
    mode: "onTouched",
    values: {
      firstName: user?.first_name || "",
      lastName: user?.last_name || "",
      phoneNumber: user?.phone || "",
      email: user?.email || "",
      [InputName.formality]: "",
      [InputName.question]: "",
      [InputName.questionThemeId]: "",
    },
  });

  const { control, handleSubmit, formState, setValue, setError, clearErrors } =
    formMethods;
  const { errors, isSubmitting, isValid } = formState;

  const { formalities } = formalitiesState;

  const formalityOptions = (formalities || []).map((formality) => ({
    label: `${formality.title} N°${formality.source_id}`,
    value: `${formality.source_id}-${formality.source_type}`,
  }));

  const onSubmit = async (data: FormInputs) => {
    segment.track(
      "assistance contact form: completed",
      trackingEventProperties
    );

    const {
      formality,
      question,
      questionThemeId,
      firstName,
      lastName,
      phoneNumber,
      email,
    } = data;

    const [sourceId, sourceType] = formality.split("-");

    const submitData: AssistanceTechnicalContactFormData = {
      type: AssistanceQuestionType.Technical,
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phoneNumber,
      question_theme_id: Number(questionThemeId),
      question_text: question,
      source_id: Number(sourceId),
      source_type: sourceType as FormalitySourceType,
    };

    try {
      await AssistanceService.createTicket(submitData);

      displayToast({
        content:
          "Votre problème a bien été remonté au support qui reviendra prochainement vers vous.",
        type: "success",
      });

      navigate(BuiltRoutePath.LegalAssistancePage, { replace: true });
    } catch (error: any) {
      setError("root", { message: error.message || "SUBMIT_FAILED" });
      displayToast({
        content:
          "Quelque chose s’est mal passé. Veuillez réessayer plus tard !",
        type: "error",
        options: { onClose: () => clearErrors() },
      });
    }
  };

  const onQuestionTextAreaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setValue(InputName.question, event.target.value, SET_VALUE_OPTIONS);
  };

  const _getQuestionThemes = async () => {
    const themes = await AssistanceService.getQuestionThemes(
      AssistanceQuestionType.Technical
    );
    setQuestionThemes(themes);
  };

  useEffect(() => {
    console.log("ContactTechnicalSupportPage useEffect TRIGGERED!");
    segment.track(
      "assistance contact form: displayed",
      trackingEventProperties
    );

    if (!questionThemes.length) {
      _getQuestionThemes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageContentHeader
        title={"Contacter le support technique"}
        subTitle="Le support traite les demandes du lundi au vendredi de 9h30 à 18h."
      />

      <PageContentBody>
        <FormProvider {...formMethods}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormSection>
              <FormSectionBody>
                <Fieldset>
                  <Controller
                    control={control}
                    name={InputName.questionThemeId}
                    rules={{
                      required: FORM_ERROR_MESSAGES.FIELD_REQUIRED,
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="Sélectionnez une réponse"
                        label="Quel type de problème rencontrez-vous ?"
                        options={questionThemes.map((theme) => ({
                          label: theme.name,
                          value: String(theme.id),
                        }))}
                        hasError={!!errors[field.name]?.message}
                        errorMessage={errors[field.name]?.message}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={InputName.formality}
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="Ma démarche"
                        label="Sélectionnez la démarche en lien avec votre problème"
                        options={formalityOptions}
                        hasError={!!errors.formality?.message}
                        errorMessage={errors.formality?.message}
                      />
                    )}
                  />

                  <UserInformationFieldset
                    validationRules={{
                      firstName: {
                        required: FORM_ERROR_MESSAGES.FIELD_REQUIRED,
                      },
                      lastName: {
                        required: FORM_ERROR_MESSAGES.FIELD_REQUIRED,
                      },
                      phoneNumber: {
                        required: FORM_ERROR_MESSAGES.FIELD_REQUIRED,
                      },
                      email: {
                        required: FORM_ERROR_MESSAGES.FIELD_REQUIRED,
                      },
                    }}
                  />

                  <Controller
                    control={control}
                    name={InputName.question}
                    rules={{
                      required: FORM_ERROR_MESSAGES.FIELD_REQUIRED,
                    }}
                    render={({ field }) => (
                      <TextArea
                        label="Quel est votre problème ?"
                        placeholder="Veuillez détailler avec précisions l'objet de votre demande. Plus votre message sera précis, plus nous serons efficace !"
                        onChange={onQuestionTextAreaChange}
                        errorMessage={errors.question?.message}
                      />
                    )}
                  />
                </Fieldset>
                <FormFooter>
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={!isValid || isSubmitting}
                    isLoading={isSubmitting}
                  >
                    Envoyer
                  </Button>
                </FormFooter>
              </FormSectionBody>
            </FormSection>
          </Form>
        </FormProvider>

        <Column>
          <SectionHeader>
            <Title type="H2">Nous contacter par téléphone</Title>
            <Text type="body">
              Contactez un de nos conseillers pour tout problème technique :
              problème d'accès à votre dossier, problème de connexion...
            </Text>
          </SectionHeader>
          <ButtonContactPhone />
        </Column>
      </PageContentBody>
    </>
  );
};

export default ContactTechnicalSupportPage;
