import { captureException } from "@sentry/react";
import { Select } from "@yolaw/ui-kit-components";
import useLegacySubscriptionPayment from "hooks/useLegacySubscriptionPayment";
import React from "react";
import { PaymentMethod, PaymentOption } from "services/payment";
import usePaymentFormContext from "../usePaymentFormContext";

type DropdownOptionProps = {
  value: string;
  label: string;
};
const PaymentMethodDropdown = () => {
  const paymentForm = usePaymentFormContext();
  const { availablePaymentMethods } = useLegacySubscriptionPayment();

  const getOptions = (): DropdownOptionProps[] => {
    const options: DropdownOptionProps[] = [
      {
        value: PaymentMethod.Card,
        label: "Carte de crédit ou de débit",
      },
    ];

    if (availablePaymentMethods.includes(PaymentMethod.GooglePay)) {
      options.push({
        value: PaymentMethod.GooglePay,
        label: "Google Pay",
      });
    }
    if (availablePaymentMethods.includes(PaymentMethod.ApplePay)) {
      options.push({
        value: PaymentMethod.ApplePay,
        label: "Apple Pay",
      });
    }

    return options;
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedPaymentMethod = event.target.value;

    switch (selectedPaymentMethod) {
      case PaymentMethod.Card:
      case PaymentMethod.GooglePay:
      case PaymentMethod.ApplePay:
        paymentForm.action.setPaymentMethod(selectedPaymentMethod);
        break;
      default:
        captureException(
          "[Payment Method Dropdown] unexpected payment method",
          {
            extra: {
              stateValue: paymentForm.state.paymentMethod,
              eventValue: selectedPaymentMethod,
            },
          }
        );
    }
  };

  const onDropdownClick = () => {
    paymentForm.action.setPaymentOption(PaymentOption.NewPaymentMethod);
  };

  return (
    <div className="payment-method-dropdown" onClick={onDropdownClick}>
      <Select
        name="payment-method"
        placeholder="Choisissez votre mode de paiement"
        value={paymentForm.state.paymentMethod}
        onChange={handleChange}
        options={getOptions()}
      />
    </div>
  );
};

export default React.memo(PaymentMethodDropdown);
