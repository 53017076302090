import { Text, Title } from "@yolaw/ui-kit-components";
import styled, { css } from "styled-components";

const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    row-gap: ${theme.spacing.xs}px;
  `}
`;

type PaymentMethodSectionProps = {
  title: string;
  subTitle: string;
  children: React.ReactNode;
};
const PaymentMethodSection = ({
  title,
  subTitle,
  children,
}: PaymentMethodSectionProps) => {
  return (
    <SectionContainer>
      <Title type="H3">{title}</Title>
      <Text color="neutral.dark">{subTitle}</Text>

      {children}
    </SectionContainer>
  );
};

export default PaymentMethodSection;
