import {
  Accent,
  Button,
  Checkbox,
  displayToast,
  Spinner,
  Text,
  Title,
} from "@yolaw/ui-kit-components";
import { BackButton } from "pages/components";
import BenefitList from "pages/components/subscriptions/BenefitList";
import { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SubscriptionsService } from "services";
import { SubscriptionProductBenefit } from "services/subscriptions/conf/subs-extra-data-types";
import BenefitIcon, {
  BenefitIconType,
} from "services/subscriptions/conf/utils/BenefitIcon";
import {
  computeBenefitLabelWithProductAvailability,
  findProductAvailability,
} from "services/subscriptions/conf/utils/product-benefits";
import styled, { css } from "styled-components";
import Subscription, {
  SubscriptionPlan,
  SubscriptionStatus,
} from "types/subscriptions";
import DateTimeUtils from "utils/datetime";
import TranslatorUtils from "utils/translator";
import SubscriptionViewContext from "../SubscriptionViewContext";

const ContentContainer = styled.div`
  max-width: 640px;
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    color: ${theme.colors.neutral.darker};
    row-gap: ${theme.spacing.s}px;

    @container (min-width: ${theme.breakpoints.m}px) {
      .benefits_list {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  `}
`;

const getContentByStatus = (
  subscription: Subscription | undefined,
  plan: SubscriptionPlan | undefined
): JSX.Element => {
  switch (subscription?.status) {
    case SubscriptionStatus.Active:
      return (
        <>
          Vous pourrez continuer à utiliser tous les services de votre compte
          jusqu’au terme du{" "}
          {TranslatorUtils.translate(plan?.period_unit || "month")} de
          souscription en cours, soit le{" "}
          <strong>
            {DateTimeUtils.formatDate(subscription.current_period_end_date)}
          </strong>
          .
        </>
      );
    case SubscriptionStatus.Draft:
      return (
        <>
          Votre{" "}
          <strong>
            {subscription.family.display_name || subscription.family.name}
          </strong>{" "}
          est en cours de création. Votre abonnement sera automatiquement
          réslié.
        </>
      );
    case SubscriptionStatus.Trialing:
      return (
        <>
          Vous bénéficiez de la période d'essai jusqu'au{" "}
          <strong>
            {DateTimeUtils.formatDate(subscription.trial_end_date)}
          </strong>
          . Si vous résiliez maintenant, vous pourrez continuer à utiliser tous
          les services de votre compte jusqu’à cette date.
        </>
      );
    default:
      return <></>;
  }
};

type BenefitItemProps = {
  benefit: SubscriptionProductBenefit<string, string>;
  productSlug: string;
};
const BenefitItem = ({ benefit, productSlug }: BenefitItemProps) => {
  const productAvailability = findProductAvailability(
    benefit.productAvailabilities,
    productSlug
  );

  return (
    <li key={benefit.label} className="benefit_item">
      <BenefitIcon type={BenefitIconType.Negative} />
      <Text type="small" color="neutral.dark">
        {computeBenefitLabelWithProductAvailability(
          benefit.labelLosing || benefit.label,
          productAvailability
        )}
      </Text>
    </li>
  );
};

const TerminationPage = () => {
  const navigate = useNavigate();
  const subscriptionViewContext = useContext(SubscriptionViewContext.Context);
  const { currentSubscription, currentPlan, subsLocalExtraData } =
    subscriptionViewContext.state;
  const [changeConfirmed, setChangeConfirmed] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const currentBenefits = useMemo(() => {
    const benefits = subsLocalExtraData?.benefitGroups.flatMap((g) => g.items);

    return benefits?.filter(
      (b) =>
        currentPlan?.product.slug &&
        findProductAvailability(
          b.productAvailabilities,
          currentPlan.product.slug
        ) &&
        b
    );
  }, [currentPlan?.product.slug, subsLocalExtraData?.benefitGroups]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChangeConfirmed(event.target.checked);
  };

  const goBack = () => {
    navigate("..", { replace: true });
  };

  const terminateSubscription = async () => {
    try {
      setIsLoading(true);
      setErrorMessage(undefined);

      const result = await SubscriptionsService.terminateSubscription(
        currentSubscription?.id!
      );

      displayToast({
        type: "success",
        content: result.message || "Votre demande a bien été prise en compte.",
      });

      goBack();
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <BackButton />
      <div className="content-container">
        <div className="page_title">
          <Title type="H2">
            Êtes-vous vous sûr(e) de vouloir résilier votre abonnement{" "}
            <Accent color="information.dark">
              {currentSubscription?.family.display_name ||
                currentSubscription?.family.name}
            </Accent>
            &nbsp;?
          </Title>
        </div>

        <ContentContainer>
          <Text>{getContentByStatus(currentSubscription, currentPlan)}</Text>

          <Text>À partir de cette date, vous ne pourrez plus utiliser :</Text>

          <BenefitList className="benefits_list">
            {currentBenefits?.length ? (
              currentBenefits.map((benefit) => (
                <BenefitItem
                  key={benefit.label}
                  benefit={benefit}
                  productSlug={currentPlan?.product.slug!}
                />
              ))
            ) : (
              <div style={{ width: 40 }}>
                <Spinner />
              </div>
            )}
          </BenefitList>
        </ContentContainer>

        <div className="checkbox_container">
          <Checkbox
            id="terminate-confirmation"
            onChange={handleCheckboxChange}
          />
          <label htmlFor="terminate-confirmation">
            Je confirme vouloir résilier mon{" "}
            <strong>
              {currentSubscription?.family.display_name ||
                currentSubscription?.family.name}
            </strong>{" "}
            ainsi que tous les services de gestion qui y sont rattachés.
          </label>
        </div>

        {errorMessage && (
          <Text color="error.dark" fontWeightVariant="bold">
            {errorMessage}
          </Text>
        )}

        <div className="actions_container">
          <Button variant="secondary" onClick={goBack} disabled={isLoading}>
            Annuler
          </Button>
          <Button
            variant="primary"
            disabled={!changeConfirmed}
            isLoading={isLoading}
            onClick={terminateSubscription}
          >
            Confirmer la résiliation
          </Button>
        </div>
      </div>
    </>
  );
};

export default TerminationPage;
