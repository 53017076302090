import { commonApiErrorHandler } from "pages/zen/services/apis/handlers/errors";
import ApiService from "services/api";
import {
  Subscription,
  SubscriptionFamilySlug,
  SubscriptionPlan,
  SubscriptionStatus,
  SubscriptionTier,
} from "types/subscriptions";
import SubscriptionExtraData from "./conf/subs-extra-data-types";

export type SubscriptionFilters = {
  statuses?: SubscriptionStatus[];
};

/** Find subscription by family slug.
 * Can filter by statuses.
 */
export const findSubscription = (
  subscriptions: Subscription[],
  subFamilySlug: SubscriptionFamilySlug,
  filters?: SubscriptionFilters
) => {
  const { statuses } = filters || {};
  return subscriptions.find(
    (sub) =>
      sub.family.slug === subFamilySlug &&
      (statuses?.includes(sub.status) ?? true)
  );
};

export const getTierToSell = (plan: SubscriptionPlan) => {
  return plan.tiers[0];
};

export const filterMonthlyPlansToSell = (
  plans: SubscriptionPlan[],
  subsLocalExtraData: SubscriptionExtraData<string, string>
) =>
  plans?.filter(
    (p) =>
      p.period_unit === "month" && // only take monthly plans
      (!subsLocalExtraData?.sellableProducts || // take all if no configuration found
        subsLocalExtraData?.sellableProducts.includes(p.product.slug)) // otherwise, filter by the configured ones
  );

export const getLocalExtraData = async (familySlug: SubscriptionFamilySlug) => {
  try {
    const localExtraData = await import(
      `services/subscriptions/conf/${familySlug}`
    ).then(
      (d) => d.default as SubscriptionExtraData<string, string> | undefined
    );
    return localExtraData;
  } catch (error) {
    throw error;
  }
};

export const getSubscriptions = async () => {
  try {
    const response = await ApiService.subscriptions.getSubscriptions();
    return response?.data || [];
  } catch (error) {
    throw error;
  }
};

export const getSubscriptionPlans = async (
  subFamilySlug: SubscriptionFamilySlug
) => {
  try {
    const response = await ApiService.subscriptions.getSubscriptionPlans(
      subFamilySlug
    );
    return response?.data?.results || [];
  } catch (error) {
    throw error;
  }
};

export const getSubsPlansAndLocalExtraData = async (
  subFamilySlug: SubscriptionFamilySlug
) => {
  try {
    const [localExtraData, plans] = await Promise.all([
      // Get extra data locally
      getLocalExtraData(subFamilySlug),
      // Get plans from API
      getSubscriptionPlans(subFamilySlug),
    ]);
    return { localExtraData, plans };
  } catch (error) {
    throw error;
  }
};

export const switchTier = async (
  subscriptionId: Subscription["id"],
  tierId: SubscriptionTier["id"]
) => {
  try {
    const response = await ApiService.subscriptions.switchTier(
      subscriptionId,
      tierId
    );
    if (response?.status === 200) {
      return response;
    }

    throw new Error(response?.data?.message);
  } catch (error) {
    return commonApiErrorHandler(error);
  }
};

export const getProratedAmount = async (
  subscriptionId: Subscription["id"],
  newPlanId: SubscriptionPlan["id"]
) => {
  try {
    const response = await ApiService.subscriptions.getProratedAmount(
      subscriptionId,
      newPlanId
    );
    if (response?.status === 200 && response.data) {
      return response.data;
    }

    throw new Error("Échec du calcul du montant au prorata !");
  } catch (error) {
    return commonApiErrorHandler(error);
  }
};

export const terminateSubscription = async (
  subscriptionId: Subscription["id"]
) => {
  try {
    const response = await ApiService.subscriptions.terminateSubscription(
      subscriptionId
    );
    if (response?.status === 200) {
      return response.data;
    }

    throw new Error(response?.data?.message || "Échec de la résiliation !");
  } catch (error) {
    return commonApiErrorHandler(error);
  }
};
