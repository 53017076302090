import { Column, Spinner, Text } from "@yolaw/ui-kit-components";
import { HTMLProps } from "react";
import { SubscriptionsService } from "services";
import SubscriptionExtraData from "services/subscriptions/conf/subs-extra-data-types";
import styled, { css } from "styled-components";
import { SubscriptionPlan, SubscriptionTier } from "types/subscriptions";
import { ChangePlanType } from "../../subscriptions-management/subscription-view/change-plan/ChangePlanContext";
import SubscriptionPlanItem from "./SubscriptionPlanItem";

const PlanListContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing.s}px;
  `}
`;

type Props = {
  localExtraData: SubscriptionExtraData<string, string> | undefined;
  currentTier?: SubscriptionTier;
  onPlanSelect: (plan: SubscriptionPlan) => void;
  plans: SubscriptionPlan[] | null;
  planItemConfigs?: {
    buttonPrefix?: string;
    downOrUpIdentifier?: (plan: SubscriptionPlan) => ChangePlanType;
  };
} & HTMLProps<HTMLDivElement>;

const SubscriptionPlanList = ({
  localExtraData,
  currentTier,
  onPlanSelect,
  plans,
  planItemConfigs,
  ...rest
}: Props) => {
  return (
    <PlanListContainer className="subscription-plan-list-container" {...rest}>
      {plans ? (
        plans.length ? (
          plans.map((plan) => (
            <SubscriptionPlanItem
              key={plan.id}
              benefitGroups={localExtraData?.benefitGroups}
              buttonTextPrefix={planItemConfigs?.buttonPrefix}
              buttonVariant={
                planItemConfigs?.downOrUpIdentifier?.(plan) ===
                ChangePlanType.Down
                  ? "secondary"
                  : "primary"
              }
              currentTier={currentTier}
              plan={plan}
              onPlanSelect={onPlanSelect}
              tierToSell={SubscriptionsService.getTierToSell(plan)}
            />
          ))
        ) : (
          <Text>Aucun plan trouvé</Text>
        )
      ) : (
        <Column style={{ alignItems: "center" }}>
          <Spinner />
          <Text type="small">Chargement des plans ...</Text>
        </Column>
      )}
    </PlanListContainer>
  );
};

export default SubscriptionPlanList;
