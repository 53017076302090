export const currencyFormat = (number: number, currency?: string) =>
  number.toLocaleString("fr-FR", {
    currency: currency || "EUR",
    style: "currency",
  });

const NumberUtils = {
  currencyFormat,
};

export default NumberUtils;
