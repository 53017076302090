import { displayToast } from "@yolaw/ui-kit-components";
import ApiService from "services/api";
import { Formality } from "types/formalities";

export const getFormalities = async () => {
  try {
    const response = await ApiService.formalities.getFormalities();
    return response?.data || [];
  } catch (error) {
    throw error;
  }
};

export const archiveFormalityFunnel = async (originalFormality: Formality) => {
  try {
    const response = await ApiService.formalities.archiveFormalityFunnel(
      originalFormality.source_id
    );

    // Action Failed
    if (response?.status !== 204) {
      displayToast({
        content: "Impossible d’archiver la démarche.",
        type: "error",
      });
      // Return the original formality to rollback
      return originalFormality;
    }
  } catch (error) {
    throw error;
  }
};

export const unarchiveFormalityFunnel = async (
  originalFormality: Formality
) => {
  try {
    const response = await ApiService.formalities.unarchiveFormalityFunnel(
      originalFormality.source_id
    );

    // Action Failed
    if (response?.status !== 204) {
      displayToast({
        content: "Impossible de désarchiver la démarche.",
        type: "error",
      });
      // Return the original formality to rollback
      return originalFormality;
    }
  } catch (error) {
    throw error;
  }
};
