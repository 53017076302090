import {
  CompteProFlat,
  LogOutFlat,
  PaperclipFlat,
  SettingsFlat,
  UserFlat,
} from "@yolaw/ui-kit-icons";
import { useApp, useSegment, useUser } from "hooks";
import useAppRoutes from "hooks/useAppRoutes";
import NavigationItem, { NavItemProps } from "layout/navigation/NavigationItem";
import { useMemo } from "react";
import { BuiltRoutePath } from "services/router";
import styled, { css, useTheme } from "styled-components";

const PaymentMethodNavItem = ({ onClick }: Pick<NavItemProps, "onClick">) => {
  const app = useApp();
  const theme = useTheme();
  const segment = useSegment();
  const { generateAppPath } = useAppRoutes();

  const path = useMemo(() => {
    if (app.currentLegalEntityId) {
      return generateAppPath(BuiltRoutePath.PaymentMethodsForLEPage);
    } else {
      return generateAppPath(BuiltRoutePath.PaymentMethodsPage);
    }
  }, [app.currentLegalEntityId, generateAppPath]);

  const handleClick = () => {
    segment.track("user payment method: displayed");
    onClick?.();
  };

  return (
    <NavigationItem
      icon={<CompteProFlat size="1.5em" color={theme.colors.secondary.main} />}
      label="Moyen de paiement"
      onClick={handleClick}
      path={path}
    />
  );
};

const SubscriptionNavItem = ({ onClick }: Pick<NavItemProps, "onClick">) => {
  const app = useApp();
  const segment = useSegment();
  const { generateAppPath } = useAppRoutes();

  const path = useMemo(() => {
    if (app.currentLegalEntityId) {
      return generateAppPath(BuiltRoutePath.SubscriptionsManagementForLEPage);
    } else {
      return generateAppPath(BuiltRoutePath.SubscriptionsManagementPage);
    }
  }, [app.currentLegalEntityId, generateAppPath]);

  const handleClick = () => {
    segment.track("subscription listing: displayed");
    onClick?.();
  };

  return (
    <NavigationItem
      icon={<SettingsFlat size="1.5em" />}
      label="Gérer mes abonnements"
      path={path}
      onClick={handleClick}
    />
  );
};

const MenuContainer = styled.ul`
  list-style: none;

  ${({ theme }) => css`
    background-color: ${theme.colors.common.white};
    padding: ${theme.spacing.xs}px;
    > li {
      padding: ${theme.spacing.xxxs}px 0;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }

    @media (min-width: ${theme.breakpoints.m}px) {
      > :not(:last-child) {
        border-bottom: 1px solid ${theme.colors.neutral.lighter};
      }
    }
  `}
`;

type MyAccountMenuProps = {
  onItemClick: VoidFunction;
};

const MyAccountMenu = ({ onItemClick }: MyAccountMenuProps) => {
  const user = useUser();

  return (
    <MenuContainer>
      <NavigationItem
        icon={<UserFlat size="1.5em" />}
        label="Informations personnelles"
        path={BuiltRoutePath.UserProfilePage}
        onClick={onItemClick}
      />

      <NavigationItem
        icon={<PaperclipFlat size="1.5em" />}
        label="Factures"
        path={BuiltRoutePath.InvoicesPage}
        onClick={onItemClick}
      />

      <PaymentMethodNavItem onClick={onItemClick} />

      <SubscriptionNavItem onClick={onItemClick} />

      <NavigationItem
        label="Me déconnecter"
        icon={<LogOutFlat size="1.5em" />}
        onClick={user.logout}
      />
    </MenuContainer>
  );
};

export default MyAccountMenu;
