import { Button, Text, Title } from "@yolaw/ui-kit-components";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, LinkProps } from "react-router-dom";
import styled from "styled-components";

import FormalitiesContext, { FormalityGroup } from "contexts/formalities";
import { useLegalEntity, useSegment } from "hooks";
import ZenWebinarBanner from "pages/components/Banners/ZenWebinarBanner";
import { PageContainer, PageContentBody } from "pages/components/PageStyles";
import FormalitiesPlaceholder from "./components/FormalitiesPlaceholder";
import FormalitiesSection from "./components/FormalitiesSection";
import RecommendationsSection from "./RecommendationsSection";

const FORMALITY_SECTIONS_ORDER: Array<FormalityGroup> = [
  FormalityGroup.WaitingForPayment,
  FormalityGroup.Draft,
  FormalityGroup.InProgress,
  FormalityGroup.Cancelled,
  FormalityGroup.Done,
];

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${(props) => props.theme.spacing.xs}px;
  margin-bottom: ${(props) => props.theme.spacing.m}px;

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: ${(props) => props.theme.spacing.xs}px;
  }
`;

const PageHeaderTextContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${(props) => props.theme.spacing.xxxs}px;

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    row-gap: 0;
  }
`;

const HeaderLink = styled((props: LinkProps) => <Link {...props} />)`
  flex-shrink: 0;
  align-self: flex-start;

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    align-self: center;
  }
`;

const FormalitiesSections = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${(props) => props.theme.spacing.m}px;
`;

const FormalitiesListingPage = () => {
  const segment = useSegment();
  const currentLegalEntity = useLegalEntity();

  const { state: formalitiesState } = useContext(FormalitiesContext.Context);
  const { isLoaded, formalities, groups } = formalitiesState;
  const [zenAccessURL, setZenAccessURL] = useState<string | null>(null);

  const getZenAccessURL = useCallback(async () => {
    const url = await currentLegalEntity.getZenAccessURL();
    setZenAccessURL(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLegalEntity?.id]);

  useEffect(() => {
    getZenAccessURL();
  }, [getZenAccessURL]);

  useEffect(() => {
    if (isLoaded) {
      segment.track("formality listing: displayed", {
        "formality count": formalities?.length || 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  return (
    <PageContainer>
      <PageHeader>
        <PageHeaderTextContent>
          <Title text="Toutes mes démarches" type="h1" />
          <Text text="À vous de jouer ! Complétez vos questionnaires dans vos démarches" />
        </PageHeaderTextContent>
        <HeaderLink
          to={
            process.env.REACT_APP_LEGALSTART_CMS_URL ||
            "https://www.legalstart.fr"
          }
          target="_blank"
        >
          <Button variant="secondary" size="medium">
            Commencer une démarche
          </Button>
        </HeaderLink>
      </PageHeader>
      <PageContentBody>
        {!!zenAccessURL && <ZenWebinarBanner context="formalities" />}

        {formalities?.length ? (
          <FormalitiesSections>
            {FORMALITY_SECTIONS_ORDER.map(
              (section) =>
                groups[section]?.formalities && (
                  <FormalitiesSection
                    key={section}
                    title={groups[section].title}
                    formalities={groups[section].formalities}
                  />
                )
            )}
          </FormalitiesSections>
        ) : (
          <FormalitiesPlaceholder />
        )}

        <RecommendationsSection />
      </PageContentBody>
    </PageContainer>
  );
};

export default FormalitiesListingPage;
