import ApiService from "services/api";

export const getFunnelWireTransferDetails = async (funnelId: number) => {
  try {
    const response = await ApiService.funnels.getFunnelWireTransferDetails(
      funnelId
    );
    if (response?.status === 200 && response?.data) {
      return response.data;
    }
    throw new Error("Impossible d'obtenir les détails du virement bancaire.");
  } catch (error) {
    throw error;
  }
};

export const cancelWireTransfer = async (funnelId: number) => {
  try {
    const response = await ApiService.funnels.cancelWireTransfer(funnelId);
    if (response?.status === 200 && response?.data) {
      return response.data;
    }
    throw new Error("Impossible d'annuler le virement bancaire.");
  } catch (error) {
    throw error;
  }
};
