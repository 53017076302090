import { Button } from "@yolaw/ui-kit-components";
import usePaymentFormContext from "components/payment-form/usePaymentFormContext";
import useStripePayment from "hooks/useStripePayment";
import { useContext } from "react";
import SetupPaymentMethodContext from "./SetupPaymentMethodContext";

type Props = {
  disabled: boolean;
  isLoading: boolean;
  onPaymentMethodCollected: (stripePaymentMethodId: string) => void;
};
const SetupWithCardButton = ({
  disabled,
  isLoading,
  onPaymentMethodCollected,
}: Props) => {
  const setupPaymentMethodContext = useContext(
    SetupPaymentMethodContext.Context
  );
  const { createStripePaymentMethod, getCardNumberElement } =
    useStripePayment();

  const paymentForm = usePaymentFormContext();
  const { cardHolderName } = paymentForm.state;

  const handleClick = async () => {
    try {
      setupPaymentMethodContext.dispatch({
        type: SetupPaymentMethodContext.Action.SetIsSubmitting,
        payload: true,
      });

      const cardNumberElement = getCardNumberElement();

      const { error, paymentMethod: stripePaymentMethod } =
        await createStripePaymentMethod(cardNumberElement, {
          card_holder_name: cardHolderName,
        });

      if (stripePaymentMethod) {
        onPaymentMethodCollected(stripePaymentMethod.id);
      } else {
        throw error;
      }
    } catch (error: any) {
      paymentForm.action.setPaymentError({
        source: "ERROR_SOURCES.FRONTEND_STRIPE",
        message:
          error.message || "Échec de la création d'un moyen de paiement.",
        code: String(error.code),
      });

      setupPaymentMethodContext.dispatch({
        type: SetupPaymentMethodContext.Action.SetIsSubmitting,
        payload: false,
      });
    }
  };

  return (
    <Button disabled={disabled} isLoading={isLoading} onClick={handleClick}>
      Ajouter
    </Button>
  );
};

export default SetupWithCardButton;
