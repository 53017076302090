import { AppContext } from "contexts";
import { useLegalEntity } from "hooks/useLegalEntity";
import { LoadingPage } from "pages";
import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { BuiltRoutePath } from "services/router";

export const HomeOrFormalitiesPageRouter = () => {
  const appContext = useContext(AppContext.Context);
  const currentLegalEntity = useLegalEntity();

  const { subscriptions, legalEntities } = appContext.state;
  if (!subscriptions || !legalEntities) {
    // wait until both subscriptions & LEs are loaded
    return <LoadingPage />;
  }

  const accessibleLSCompteProSub =
    currentLegalEntity.lsCompteProAccessibleSubscription;

  // no LSCP sub && trying to access BuiltRoutePath.FormalitiesPage
  // => force redirect to '{BuiltRoutePath.HomePage}' as Product team
  // wants to display the formalities there
  if (
    !accessibleLSCompteProSub &&
    window.location.pathname === BuiltRoutePath.FormalitiesPage
  ) {
    return <Navigate to={BuiltRoutePath.HomePage} replace />;
  }

  return <Outlet />;
};
