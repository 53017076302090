import { Button, Column, Title } from "@yolaw/ui-kit-components";
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import { useLegalEntity, useSegment } from "hooks";
import { PageContainer } from "pages/components/PageStyles";
import { LegacySubscriptionSlug } from "types/legacy-subscriptions";
import LSComptaProInfo from "./LSComptaProInfo";
import LSComptaWebinarBanner from "./LSComptaWebinarBanner";
import lsComptaPreviewImg from "./assets/ls-compta-preview.png";

const HeaderPreviewImg = styled.img`
  ${({ theme }) => css`
    @container (min-width: ${theme.breakpoints.m}px) {
      max-width: 380px;
    }
  `}
`;

const HeaderTitleContainer = styled<React.ElementType>(Column)`
  ${({ theme }) => css`
    && {
      row-gap: ${theme.spacing.s}px;

      @container (min-width: ${theme.breakpoints.m}px) {
        align-items: flex-start;
      }
    }
  `}
`;

const PageHeader = styled.div`
  display: flex;
  ${({ theme }) => css`
    gap: 40px;

    @container (max-width: ${theme.breakpoints.m}px) {
      flex-direction: column;
    }
  `}
`;

const PageContainerInner = styled<React.ElementType>(Column)`
  container-type: inline-size;

  && {
    row-gap: ${({ theme }) => theme.spacing.l}px;
  }
`;

const LSComptaBasicPage = () => {
  const [accessURL, setAccessURL] = useState(
    process.env.REACT_APP_LS_COMPTA_BASIC_ACCESS_URL
  );

  const segment = useSegment();

  const currentLegalEntity = useLegalEntity();
  const lsComptaBasicSub = currentLegalEntity.findLegacySubscription(
    LegacySubscriptionSlug.LSComptaBasic
  );

  useEffect(() => {
    if (lsComptaBasicSub?.redirection_url) {
      // override the URL provide along with the subscription
      setAccessURL(lsComptaBasicSub.redirection_url);
    }
  }, [lsComptaBasicSub?.redirection_url]);

  useEffect(() => {
    segment.track("comptastart: displayed", {
      "sub comptastart": lsComptaBasicSub?.status || "none",
      cycle: lsComptaBasicSub?.plan.period_unit,
      "price (ht)": lsComptaBasicSub?.plan.price_et,
      type: lsComptaBasicSub?.plan.type_slug,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Prevent non-subscriber to access the page
  if (!lsComptaBasicSub)
    throw new Error("Vous n'êtes pas éligible pour accéder à cette page !");

  const openAccountingURL = () => {
    window.open(accessURL);

    segment.track("ls compta software url: clicked", {
      "sub comptastart": lsComptaBasicSub?.status || "none",
      cycle: lsComptaBasicSub?.plan.period_unit,
      "price (ht)": lsComptaBasicSub?.plan.price_et,
      type: lsComptaBasicSub?.plan.type_slug,
    });
  };

  return (
    <PageContainer>
      <PageContainerInner>
        <PageHeader>
          <HeaderTitleContainer>
            <Title type="H2">
              Vous êtes abonné à LS&nbsp;Compta&nbsp;Basic
            </Title>
            <Button onClick={openAccountingURL}>Ouvrir mon logiciel</Button>
          </HeaderTitleContainer>
          <HeaderPreviewImg src={lsComptaPreviewImg} alt="ls-compta-preview" />
        </PageHeader>

        <LSComptaWebinarBanner />

        <LSComptaProInfo />
      </PageContainerInner>
    </PageContainer>
  );
};

export default LSComptaBasicPage;
