import { Button, Column, Hint, HintType, Text } from "@yolaw/ui-kit-components";
import Modal from "components/modal/Modal";
import { ModalName } from "contexts/app";
import { useApp } from "hooks";
import { useState } from "react";
import { FunnelService } from "services";

type Props = {
  funnelId: number;
};
const FunnelWireTransferChangePaymentMethodModal = ({ funnelId }: Props) => {
  const app = useApp();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const cancelWireTransfer = async () => {
    try {
      setIsLoading(true);
      const result = await FunnelService.cancelWireTransfer(funnelId);
      window.location.replace(result.redirection_url);
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={
        app.openingModal?.name ===
        ModalName.FunnelWireTransferChangePaymentMethod
      }
      isLocked={isLoading}
      onClose={app.closeModal}
      title="Modifier le moyen de paiement"
      FooterContent={
        <>
          <Button
            variant="secondary"
            disabled={isLoading}
            onClick={app.closeModal}
          >
            Annuler
          </Button>
          <Button isLoading={isLoading} onClick={cancelWireTransfer}>
            Confirmer
          </Button>
        </>
      }
    >
      <Column style={{ textAlign: "center" }}>
        <Text>
          Si vous choisissez un nouveau moyen de paiement, la demande de
          virement sera annulée et un nouveau numéro de référence sera généré.
        </Text>

        {errorMessage && <Hint type={HintType.Error}>{errorMessage}</Hint>}
      </Column>
    </Modal>
  );
};

export default FunnelWireTransferChangePaymentMethodModal;
