import {
  ProratedAmountResponseData,
  Subscription,
  SubscriptionPlan,
  SubscriptionTier,
  TerminateSubscriptionResponseData,
} from "types/subscriptions";
import coreApiInstance from "../coreApi";

const BASE_ENDPOINT = "/v2/subscriptions";

/** Get all subscriptions */
export const getSubscriptions = () =>
  coreApiInstance.get<Subscription[]>(`${BASE_ENDPOINT}/`);

export const getSubscriptionPlans = (
  subscriptionFamilySlug: Subscription["family"]["slug"]
) =>
  coreApiInstance.get<CoreAPIPaginatedResponse<SubscriptionPlan>>(
    `${BASE_ENDPOINT}/plans/`,
    {
      params: {
        family: subscriptionFamilySlug,
      },
    }
  );

export const switchTier = (
  subscriptionId: Subscription["id"],
  tierId: SubscriptionTier["id"]
) =>
  coreApiInstance.post(`${BASE_ENDPOINT}/${subscriptionId}/switch-tier/`, {
    tier_id: tierId,
  });

export const getProratedAmount = (
  subscriptionId: Subscription["id"],
  newPlanId: SubscriptionPlan["id"]
) =>
  coreApiInstance.get<ProratedAmountResponseData>(
    `${BASE_ENDPOINT}/${subscriptionId}/get-prorata-amount/${newPlanId}/`
  );

export const terminateSubscription = (subscriptionId: Subscription["id"]) =>
  coreApiInstance.post<TerminateSubscriptionResponseData>(
    `${BASE_ENDPOINT}/${subscriptionId}/cancel/`
  );
