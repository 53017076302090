import React from "react";
import { Formality, FormalityStatus } from "types/formalities";

export enum FormalityGroup {
  Cancelled = "cancelled",
  Done = "done",
  Draft = "draft",
  InProgress = "in_progress",
  WaitingForPayment = "waiting_for_payment",
}

const GROUP_BY_FORMALITY_STATUSES: Record<
  FormalityGroup,
  Array<Formality["status"]>
> = {
  [FormalityGroup.Cancelled]: [FormalityStatus.Cancelled],
  [FormalityGroup.Done]: [FormalityStatus.Done],
  [FormalityGroup.Draft]: [FormalityStatus.Draft],
  [FormalityGroup.InProgress]: [FormalityStatus.InProgress],
  [FormalityGroup.WaitingForPayment]: [
    FormalityStatus.WireTransferFrozen,
    FormalityStatus.WireTransferPending,
  ],
};

const GROUP_TITLE: Record<FormalityGroup, string> = {
  [FormalityGroup.Cancelled]: "Vos démarches abandonnées",
  [FormalityGroup.Done]: "Vos démarches terminées",
  [FormalityGroup.Draft]: "Vos démarches débutées récemment",
  [FormalityGroup.InProgress]: "Démarches en cours",
  [FormalityGroup.WaitingForPayment]: "Démarches à payer",
};

export type FormalitiesContextState = {
  isLoaded: boolean;
  formalities: Formality[] | null;
  groups: Record<
    FormalityGroup,
    {
      formalities: Formality[];
      title: string;
    }
  >;
};

const _filterFormalitiesByGroup = (
  formalities: Formality[],
  group: FormalityGroup
) => {
  return formalities.filter((formality) =>
    GROUP_BY_FORMALITY_STATUSES[group].includes(formality.status)
  );
};

const _groupFormalitiesByStatus = (formalities: Formality[]) => {
  return Object.values(FormalityGroup).reduce((obj, group) => {
    obj[group] = {
      formalities: _filterFormalitiesByGroup(formalities, group),
      title: GROUP_TITLE[group],
    };

    return obj;
  }, {} as FormalitiesContextState["groups"]);
};

export const _groupFormalities = (
  state: FormalitiesContextState,
  formalities: Formality[]
): FormalitiesContextState["groups"] => {
  return {
    ...state.groups,
    ..._groupFormalitiesByStatus(formalities),
  };
};

const initialState: FormalitiesContextState = {
  isLoaded: false,
  formalities: null,
  groups: _groupFormalitiesByStatus([]),
};

export enum ActionType {
  SetFormalities,
  UpdateFormality,
}

export type FormalitiesContextAction =
  | {
      type: ActionType.SetFormalities;
      payload: Formality[];
    }
  | {
      type: ActionType.UpdateFormality;
      payload: Formality;
    };

const reducer = (
  state: FormalitiesContextState,
  action: FormalitiesContextAction
): FormalitiesContextState => {
  switch (action.type) {
    case ActionType.SetFormalities:
      return {
        ...state,
        isLoaded: true,
        formalities: action.payload,
        groups: _groupFormalities(state, action.payload),
      };
    case ActionType.UpdateFormality:
      const updatedFormalities = (state.formalities || []).map((formality) => {
        if (
          formality.source_type === action.payload.source_type &&
          formality.source_id === action.payload.source_id
        ) {
          return action.payload;
        } else {
          return formality;
        }
      });
      return {
        ...state,
        formalities: updatedFormalities,
        groups: _groupFormalities(state, updatedFormalities),
      };
    default:
      throw new Error("[FormalitiesContext] unknown action type");
  }
};

type FormalitiesContextType = {
  state: FormalitiesContextState;
  dispatch: React.Dispatch<FormalitiesContextAction>;
};

const Context = React.createContext<FormalitiesContextType>({
  state: initialState,
  dispatch: () => null,
});

Context.displayName = "FormalitiesContext";

const FormalitiesContext = {
  ActionType,
  Context,
  initialState,
  reducer,
};

export default FormalitiesContext;
