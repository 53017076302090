import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  CreatePaymentMethodCardData,
  MetadataParam,
  PaymentIntent,
  PaymentIntentResult,
  PaymentRequest,
  SetupIntent,
  SetupIntentResult,
  BillingDetails as StripeBillingDetails,
} from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import LegacySubscription from "types/legacy-subscriptions";

const useStripePayment = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [stripePaymentRequest, setStripePaymentRequest] =
    useState<PaymentRequest | null>(null);

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: "FR",
        currency: "eur",
        total: {
          label: "Default",
          amount: 100, // amount should be in subunit => x100 cents
          pending: true,
        },
        requestPayerName: true,
        requestPayerEmail: true,
        disableWallets: ["link", "googlePay", "browserCard"],
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setStripePaymentRequest(pr);
        }
      });
    }
  }, [stripe]);

  /** Check whether the Apple Pay is qualified to make payment or not.
   * Available wallets:
   * - applePay
   * - googlePay
   */
  const canMakeApplePayPayment = () => !!stripePaymentRequest;

  /**Get Stripe's CardNumberElement from card input form */
  const getCardNumberElement = () => {
    if (!elements) {
      throw new Error("missing_stripe_elements");
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    if (!cardNumberElement) {
      throw new Error("missing_stripe_card_number_elements");
    }

    return cardNumberElement;
  };

  const createStripePaymentMethod = (
    cardData?: CreatePaymentMethodCardData["card"],
    metadata?: MetadataParam,
    billing_details?: StripeBillingDetails
  ) => {
    if (!stripe) return Promise.reject(new Error("Stripe is not ready"));

    return stripe.createPaymentMethod({
      type: "card",
      card: cardData || getCardNumberElement(),
      metadata,
      billing_details,
    });
  };

  const handleStripeCardAction = async (
    stripeClientSecret: string,
    intentType: PaymentIntent["object"] | SetupIntent["object"]
  ) => {
    if (!stripe) return;

    // Let Stripe.js handle the rest of the payment flow.
    switch (intentType) {
      case "payment_intent":
        return stripe.confirmCardPayment(stripeClientSecret);
      case "setup_intent":
        return stripe.confirmCardSetup(stripeClientSecret);
      default:
        throw new Error("Impossible de gérer l'action de la carte !", {
          cause: "invalid_intent_type",
        });
    }
  };

  const isDirectPayment = (
    data: LegacySubscription.StripePayment.InitialRequestResponse
  ): data is LegacySubscription.StripePayment.DirectPaymentResponse =>
    "payment_intent_id" in data;

  const isIndirectPayment = (
    data: LegacySubscription.StripePayment.InitialRequestResponse
  ): data is LegacySubscription.StripePayment.IndirectPaymentResponse =>
    "setup_intent_id" in data;

  const isPaymentIntentResult = (
    data: Awaited<ReturnType<typeof handleStripeCardAction>>
  ): data is PaymentIntentResult => !!data && "paymentIntent" in data;

  const isSetupIntentResult = (
    data: Awaited<ReturnType<typeof handleStripeCardAction>>
  ): data is SetupIntentResult => !!data && "setupIntent" in data;

  return {
    /** It's only available if amount to pay is larger than 0 and browser can make payment via ApplePay */
    stripePaymentRequest,
    canMakeApplePayPayment,
    createStripePaymentMethod,
    getCardNumberElement,
    handleStripeCardAction,
    isDirectPayment,
    isIndirectPayment,
    isPaymentIntentResult,
    isSetupIntentResult,
  };
};

export default useStripePayment;
