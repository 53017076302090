import { Button, Column, Text, Title } from "@yolaw/ui-kit-components";
import Modal from "components/modal/Modal";
import { useApp, useIsMobile } from "hooks";
import useAppRoutes from "hooks/useAppRoutes";
import workingOnItImgSrc from "pages/subscriptions-management/assets/undraw_dev_focus_re_6iwt 1.svg";
import { useContext } from "react";
import { BuiltRoutePath } from "services/router";
import { useTheme } from "styled-components";
import ChangePlanContext, { ChangePlanType } from "../ChangePlanContext";

const getContent = (downOrUp?: ChangePlanType) => {
  switch (downOrUp) {
    case ChangePlanType.Down:
      return (
        <>
          <Text>
            Votre nouveau plan sera actif à partir du prochain cycle de
            facturation.
          </Text>
          <Text>
            En attendant, vous pouvez continuer à profiter des fonctionnalités
            de votre plan actuel.
          </Text>
        </>
      );
    case ChangePlanType.Up:
    default:
      return <Text>Il sera effectif sous 4 heures ouvrées.</Text>;
  }
};

const SwitchTierSuccessModal = () => {
  const app = useApp();
  const isMobile = useIsMobile();
  const theme = useTheme();
  const { navigateTo } = useAppRoutes();
  const changePlanContext = useContext(ChangePlanContext.Context);

  const closeModalAndStay = () => {
    app.closeModal();
  };

  const closeModalAndNavigateToSubscriptionListing = () => {
    app.closeModal();
    navigateTo(BuiltRoutePath.SubscriptionsManagementPage, { replace: true });
  };

  return (
    <Modal isOpen={true} onClose={closeModalAndStay}>
      <Column style={{ rowGap: `${theme.spacing.s}px`, alignItems: "center" }}>
        <img
          src={workingOnItImgSrc}
          style={{ width: 162, height: 104 }}
          alt="working on it"
        />
        <span style={{ textAlign: isMobile ? "left" : "center" }}>
          <Title type={isMobile ? "H1" : "H3"}>
            Votre changement a bien été pris en compte
          </Title>
        </span>
        <Column
          style={{
            rowGap: 0,
            textAlign: isMobile ? "left" : "center",
            color: theme.colors.neutral.darker,
          }}
        >
          {getContent(changePlanContext.state.downOrUp)}
        </Column>

        <Button onClick={closeModalAndNavigateToSubscriptionListing}>
          J'ai compris
        </Button>
      </Column>
    </Modal>
  );
};

export default SwitchTierSuccessModal;
