import { PaymentRequestPaymentMethodEvent } from "@stripe/stripe-js";
import { displayToast } from "@yolaw/ui-kit-components";
import usePaymentFormContext from "components/payment-form/usePaymentFormContext";
import { useApp } from "hooks";
import { useContext, useEffect } from "react";
import { PaymentMethod } from "services/payment";
import SetupPaymentMethodContext from "./SetupPaymentMethodContext";
import SetupWithApplePayButton from "./SetupWithApplePayBtn";
import SetupWithCardButton from "./SetupWithCardBtn";
import SetupWithGooglePayBtn from "./SetupWithGooglePayBtn";
import usePaymentMethodSetup from "./usePaymentMethodSetup";

const SetupConfirmButton = () => {
  const app = useApp();
  const setupPaymentMethodContext = useContext(
    SetupPaymentMethodContext.Context
  );
  const { isSubmitting, modalRef, setupFor } = setupPaymentMethodContext.state;

  const paymentForm = usePaymentFormContext();
  const { isReadyToPay, paymentMethod } = paymentForm.state;

  const paymentMethodSetup = usePaymentMethodSetup({
    setupFor: setupFor,
    paymentModalRef: modalRef,
  });

  const { setupError } = paymentMethodSetup;

  const shouldDisableButton = !isReadyToPay || isSubmitting;

  useEffect(() => {
    if (setupError) {
      paymentForm.action.setPaymentError(setupError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setupError]);

  const handleAddPaymentMethod = async (
    stripePaymentMethodId: string,
    event?: PaymentRequestPaymentMethodEvent
  ) => {
    try {
      setupPaymentMethodContext.dispatch({
        type: SetupPaymentMethodContext.Action.SetIsSubmitting,
        payload: true,
      });

      await paymentMethodSetup.initiateNewPaymentMethod(
        stripePaymentMethodId,
        event
      );

      app.closeModal();

      displayToast({
        type: "success",
        content: "Votre moyen de paiement a bien été mis à jour.",
      });
    } catch (error) {
      // TODO: handle error
    } finally {
      setupPaymentMethodContext.dispatch({
        type: SetupPaymentMethodContext.Action.SetIsSubmitting,
        payload: false,
      });
    }
  };

  if (paymentMethod === PaymentMethod.Card) {
    return (
      <SetupWithCardButton
        disabled={shouldDisableButton}
        isLoading={isSubmitting}
        onPaymentMethodCollected={handleAddPaymentMethod}
      />
    );
  }

  if (paymentMethod === PaymentMethod.ApplePay) {
    return (
      <SetupWithApplePayButton
        disabled={shouldDisableButton}
        isLoading={isSubmitting}
        onPaymentMethodCollected={handleAddPaymentMethod}
      />
    );
  }

  if (paymentMethod === PaymentMethod.GooglePay) {
    return (
      <SetupWithGooglePayBtn
        disabled={shouldDisableButton}
        isLoading={isSubmitting}
        onPaymentMethodCollected={handleAddPaymentMethod}
      />
    );
  }

  return <></>;
};

export default SetupConfirmButton;
