import { Formality } from "./formalities";

interface AssistanceQuestionSubTheme {
  id: number;
  name: string;
  type: AssistanceQuestionType;
  parent: number;
}

export enum AssistanceQuestionType {
  AssistanceJuridique = "aj",
  Formalist = "formalist",
  Technical = "technical",
}

export interface AssistanceQuestionTheme {
  id: number;
  name: string;
  type: AssistanceQuestionType;
  subthemes: Array<AssistanceQuestionSubTheme>;
}

interface AssistanceContactFormData_UserInfo {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
}

export interface AssistanceAJContactFormData
  extends AssistanceContactFormData_UserInfo {
  type: AssistanceQuestionType.AssistanceJuridique;

  question_theme_id:
    | AssistanceQuestionSubTheme["id"]
    | AssistanceQuestionTheme["id"];
  question_text: string;
}

export interface AssistanceFormalistContactFormData
  extends AssistanceContactFormData_UserInfo {
  type: AssistanceQuestionType.Formalist;

  source_type: Formality["source_type"];
  source_id: Formality["source_id"];

  question_text: string;
}

export interface AssistanceTechnicalContactFormData
  extends AssistanceContactFormData_UserInfo {
  type: AssistanceQuestionType.Technical;

  source_type: Formality["source_type"];
  source_id: Formality["source_id"];

  question_theme_id: AssistanceQuestionTheme["id"];
  question_text: string;
}

export type AssistanceContactFormData =
  | AssistanceAJContactFormData
  | AssistanceFormalistContactFormData
  | AssistanceTechnicalContactFormData;

export type AppointmentLocationState = {
  questionTheme?: AssistanceQuestionTheme["name"];
  questionSubTheme?: AssistanceQuestionSubTheme["name"];
  questionText: string;
};
