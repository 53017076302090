import { Hint, HintType, Spinner, Title } from "@yolaw/ui-kit-components";
import { FormalitiesContext, LayoutContext } from "contexts";
import { useFluidLayout } from "hooks";
import { BackButton } from "pages/components";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { FormalitySourceType } from "types/formalities";
import { RoutePathPathParams } from "../router";
import WireTransferInfoCard from "./WireTransferInfoCard";

const MainContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    .content-container {
      max-width: 944px;
      margin: ${theme.spacing.l}px auto;
      padding-inline: ${theme.spacing.xs}px;
      display: flex;
      flex-direction: column;
      row-gap: ${theme.spacing.l}px;
      align-items: center;
    }
  `}
`;

const FunnelWireTransferDetailsPage = () => {
  useFluidLayout();

  const { funnelId: funnelIdInParams } =
    useParams<RoutePathPathParams["FunnelWireTransferDetailsPage"]>();
  if (!funnelIdInParams || isNaN(Number(funnelIdInParams))) {
    throw Error("L'ID de la démarche est manquant ou invalide !");
  }

  const layoutContext = useContext(LayoutContext.Context);
  const { state: formalitiesState } = useContext(FormalitiesContext.Context);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  useEffect(() => {
    layoutContext.dispatch({ type: "SET_LE_SELECTOR_VISIBLE", payload: false });

    return () => {
      layoutContext.dispatch({
        type: "SET_LE_SELECTOR_VISIBLE",
        payload: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const funnelFormality = formalitiesState.formalities?.find(
    (formality) =>
      formality.source_type === FormalitySourceType.Funnel &&
      formality.source_id === Number(funnelIdInParams)
  );

  if (!formalitiesState.formalities) {
    return <Spinner />;
  }

  if (!funnelFormality) {
    throw Error(`Impossible de trouver la démarche : ${funnelIdInParams}`);
  }

  const { title, source_id } = funnelFormality;

  return (
    <MainContainer>
      <BackButton />
      <div className="content-container">
        <Title type="H2">
          Paiement de {title}&nbsp;n°{source_id}
        </Title>

        {errorMessage ? (
          <Hint type={HintType.Error}>{errorMessage}</Hint>
        ) : (
          <WireTransferInfoCard
            funnelId={source_id}
            onError={setErrorMessage}
          />
        )}
      </div>
    </MainContainer>
  );
};

export default FunnelWireTransferDetailsPage;
