import { ApiService, AuthService } from "services";
import { ChangeEmailConfirmData } from "types/user";
import { ERROR_MESSAGES } from "utils/constants";

export const signup = async (data: SignupRequestData) => {
  try {
    const response = await ApiService.users.signup(data);
    if (response?.status === 201 && response.data) {
      const { access, refresh } = response.data;
      AuthService.onAuthChange(access, refresh);
      return response.data;
    }

    throw new Error(response?.data?.error || ERROR_MESSAGES.DEFAULT);
  } catch (error) {
    throw error;
  }
};

export const initialEmail = async (data: InitialEmailData) => {
  try {
    const response = await ApiService.users.initialEmail(data);

    if (response?.status !== 204) {
      throw Error(ERROR_MESSAGES.DEFAULT);
    }
  } catch (error) {
    throw error;
  }
};

export const updateInitialEmail = async (data: UpdateInitialEmailData) => {
  try {
    const response = await ApiService.users.updateInitialEmail(data);

    if (response?.status !== 204) {
      throw Error(response?.data?.error || ERROR_MESSAGES.DEFAULT);
    }
  } catch (error) {
    throw error;
  }
};

export const changeEmailInitiate = async () => {
  try {
    const response = await ApiService.users.changeEmailInitiate();
    if (response?.status !== 204) {
      throw Error(ERROR_MESSAGES.DEFAULT);
    }
  } catch (error) {
    throw error;
  }
};

export const changeEmailConfirm = async (data: ChangeEmailConfirmData) => {
  try {
    const response = await ApiService.users.changeEmailConfirm(data);
    if (response?.status !== 204) {
      throw Error(
        "Une erreur est survenue lors de la modification de votre email. Merci de réessayer."
      );
    }
  } catch (error) {
    throw error;
  }
};

/** @deprecated use `getPaymentMethod` instead */
export const getPaymentMethods = async () => {
  try {
    const response = await ApiService.users.getPaymentMethods();
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDefaultPaymentMethod = async () => {
  try {
    const response = await ApiService.users.getDefaultPaymentMethod();
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const initiateNewPaymentMethod = async (
  stripePaymentMethodId: string
) => {
  try {
    const response = await ApiService.users.initiateNewPaymentMethod(
      stripePaymentMethodId
    );

    if (response?.status === 200 && response?.data) {
      return response.data;
    }

    throw new Error(
      (response?.data as any)?.error ||
        "Échec de la création d'un nouveau moyen de paiement."
    );
  } catch (error) {
    throw error;
  }
};

export const confirmNewPaymentMethod = async (
  setupIntentId: string,
  stripePaymentMethodId: string
) => {
  try {
    const response = await ApiService.users.confirmNewPaymentMethod(
      setupIntentId,
      stripePaymentMethodId
    );

    if (response?.status === 200 && response?.data) {
      return response.data;
    }

    throw new Error(
      (response?.data as any)?.error ||
        "Échec de la confirmation du nouveau moyen de paiement."
    );
  } catch (error) {
    throw error;
  }
};

export const detachDefaultPaymentMethod = async () => {
  try {
    const response = await ApiService.users.detachDefaultPaymentMethod();

    if (response?.status === 204) {
      return response?.data;
    }

    throw new Error(
      (response?.data as any)?.error ||
        "Échec de la suppression du moyen de paiement."
    );
  } catch (error) {
    throw error;
  }
};
