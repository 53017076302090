import {
  PaymentRequestPaymentMethodEvent,
  SetupIntent as StripeSetupIntent,
} from "@stripe/stripe-js";
import { CustomHTMLDialogElement } from "components/modal/Modal";
import { useApp, useLegalEntity } from "hooks";
import useStripePayment from "hooks/useStripePayment";
import { useState } from "react";
import { LegalEntityService, UserService } from "services";
import { LSPaymentMethod } from "types";

type SetupError = {
  source: string;
  message: string;
  code: string;
};

type HookInputs = {
  setupFor: LSPaymentMethod.CardProcessorType;
  paymentModalRef?: React.MutableRefObject<CustomHTMLDialogElement | null> | null;
};
const usePaymentMethodSetup = (options: HookInputs) => {
  const app = useApp();
  const legalEntity = useLegalEntity();
  const { handleStripeCardAction, isSetupIntentResult } = useStripePayment();

  const [setupError, setSetupError] = useState<SetupError | null>(null);

  const { setupFor, paymentModalRef } = options;
  const setupForCompany =
    setupFor === LSPaymentMethod.CardProcessorType.Company;

  const onSetupError = (error: SetupError) => {
    setSetupError(error);
    throw error;
  };

  const onSetupSuccess = async (
    newPaymentMethod: LSPaymentMethod.PaymentMethodObject
  ) => {
    const updatedPMs = (app.paymentMethods || [])?.map((pm) => {
      if (pm.card_processor_type === newPaymentMethod.card_processor_type) {
        return newPaymentMethod;
      } else {
        return pm;
      }
    });
    // Update app context
    app.setPaymentMethods(updatedPMs);
  };

  const confirmNewPaymentMethod = async (
    setupIntent: StripeSetupIntent,
    stripePaymentMethodId: string
  ) => {
    try {
      const confirmResult = setupForCompany
        ? await LegalEntityService.confirmNewPaymentMethod(
            legalEntity.id,
            setupIntent.id,
            stripePaymentMethodId
          )
        : await UserService.confirmNewPaymentMethod(
            setupIntent.id,
            stripePaymentMethodId
          );
      onSetupSuccess(confirmResult);
    } catch (error: any) {
      onSetupError({
        source: "ERROR_SOURCES.BACKEND_STRIPE",
        message: error.message,
        code: error.code,
      });
    }
  };

  const handleCardAction = async (
    clientSecret: string,
    stripePaymentMethodId: string
  ) => {
    // Hide the payment modal while Stripe is handling the card action
    paymentModalRef?.current?.hide();
    const handleCardActionResult = await handleStripeCardAction(
      clientSecret,
      "setup_intent"
    );
    // Reappear the payment modal to show the result and continue payment process
    paymentModalRef?.current?.unhide();

    if (handleCardActionResult) {
      const { error } = handleCardActionResult;
      if (error) {
        onSetupError({
          source: "ERROR_SOURCES.FRONTEND_STRIPE",
          message: error.message || "",
          code: String(error.code),
        });
      } else {
        if (isSetupIntentResult(handleCardActionResult)) {
          const { setupIntent } = handleCardActionResult;
          await confirmNewPaymentMethod(setupIntent, stripePaymentMethodId);
        }
      }
    }
  };

  const initiateNewPaymentMethod = async (
    stripePaymentMethodId: string,
    event?: PaymentRequestPaymentMethodEvent
  ) => {
    // Clear previously payment error if any
    setSetupError(null);

    try {
      const initiateResult = setupForCompany
        ? await LegalEntityService.initiateNewPaymentMethod(
            legalEntity.id,
            stripePaymentMethodId
          )
        : await UserService.initiateNewPaymentMethod(stripePaymentMethodId);

      const { setup_intent_status, client_secret } = initiateResult;
      switch (setup_intent_status) {
        case "requires_action":
          if (client_secret) {
            await handleCardAction(client_secret, stripePaymentMethodId);
          } else {
            throw new Error("Stripe client secret is not provided");
          }
          break;
        case "succeeded":
          await onSetupSuccess(initiateResult);
          break;
        default:
          throw new Error("Invalid setup intent status");
      }
      if (event) event.complete("success");
    } catch (error: SetupError | any) {
      if (event) event.complete("fail");
      onSetupError({
        source: "ERROR_SOURCES.BACKEND_STRIPE",
        message: error.message,
        code: error.code,
      });
    }
  };

  return {
    setupError,
    initiateNewPaymentMethod,
  };
};

export default usePaymentMethodSetup;
