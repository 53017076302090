import { Button, Label, Row, Tag } from "@yolaw/ui-kit-components";
import { TextButtonProps } from "@yolaw/ui-kit-components/types/ui-kit-components/src/components/button";
import { formatDate } from "date-fns/format";
import { useSegment } from "hooks";
import useAppRoutes from "hooks/useAppRoutes";
import { FC } from "react";
import { BuiltRoutePath } from "services/router";
import styled, { css } from "styled-components";
import {
  Formality,
  FormalitySourceType,
  FormalityStatus,
} from "types/formalities";
import {
  RoutePath as FormalityFunnelViewRoutePath,
  generateFormalityFunnelViewPath,
} from "../funnel-view/router";
import { useFormality } from "../useFormality";
import FormalityOptionsMenu from "./FormalityOptionsMenu";

const Container = styled.div`
  display: flex;

  ${({ theme }) => css`
    padding: ${theme.spacing.xs}px;
    background: ${theme.colors.common.white};
    border-radius: ${theme.borderRadius.s}px;
    box-shadow: ${theme.shadows.blue.small};

    flex-direction: column;
    row-gap: ${theme.spacing.xxxs}px;

    @container (min-width: ${theme.breakpoints.m}px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      column-gap: ${theme.spacing.xs}px;
    }
  `};
`;

const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${theme.spacing.xxxs}px;
    flex: 2;

    @container (min-width: ${theme.breakpoints.m}px) {
      flex-direction: row;
      align-items: center;
    }
  `};
`;

const Body = styled<React.ElementType>(Row)`
  align-items: center;
  flex-wrap: wrap;

  .tag_update {
    flex: 3;
  }

  .actions_container {
    display: flex;
    gap: inherit;
    flex: 1;
    justify-content: flex-end;

    .formality_cta {
      flex: 1;
    }
  }
`;

const StyledTag = styled(Tag)`
  flex-shrink: 0;
  flex-grow: 0;
`;

const FormalityCTA = ({
  formality: rawFormality,
  ctaVariant,
}: FormalityCardProps) => {
  const { navigateTo } = useAppRoutes();
  const segment = useSegment();
  const formality = useFormality(rawFormality);
  const { detail_url, source_id, source_type, status } = formality;

  const commonButtonProps: Partial<TextButtonProps> = {
    className: "formality_cta",
    type: "button",
    size: "small",
  };

  const trackFormalityClick = () => {
    segment.track("formality: clicked", {
      "formality id": source_id, // ⚠️ For tracking purpose only. Technically, a formality doesn't have an actual ID
      "legalentity id": formality.legal_entity_id,
      source_type: formality.source_type,
      source_id: source_id,
    });
  };

  if (status === FormalityStatus.Cancelled) {
    const unarchiveFormality = () => {
      trackFormalityClick();
      formality.unarchiveFormalityFunnel();
    };

    return (
      <Button
        {...commonButtonProps}
        variant="tertiary"
        onClick={unarchiveFormality}
      >
        Désarchiver
      </Button>
    );
  }

  if (
    source_type === FormalitySourceType.Funnel &&
    (status === FormalityStatus.WireTransferFrozen ||
      status === FormalityStatus.WireTransferPending)
  ) {
    const openWireTransferDetails = () => {
      trackFormalityClick();
      const funnelWireTransferDetailsPagePath = generateFormalityFunnelViewPath(
        FormalityFunnelViewRoutePath.FunnelWireTransferDetailsPage,
        {
          funnelId: source_id,
        }
      );
      navigateTo(
        `${BuiltRoutePath.FormalitiesPage}/${funnelWireTransferDetailsPagePath}`
      );
    };

    return (
      <Button
        {...commonButtonProps}
        variant={ctaVariant || "primary"}
        onClick={openWireTransferDetails}
      >
        Payer
      </Button>
    );
  }

  const openFormality = () => {
    trackFormalityClick();
    window.open(detail_url);
  };

  return (
    <Button
      {...commonButtonProps}
      variant={ctaVariant || "primary"}
      onClick={openFormality}
    >
      Consulter
    </Button>
  );
};

type FormalityCardProps = {
  formality: Formality;
  ctaVariant?: TextButtonProps["variant"];
};

const FormalityCard: FC<FormalityCardProps> = ({ formality, ctaVariant }) => {
  const {
    source_id: sourceId,
    title,
    updated_at: updatedAt,
    canceled_at,
    status,
  } = formality;

  const updateAtTagText = `Mise à jour le : ${formatDate(
    new Date(updatedAt),
    "dd/MM/yyyy"
  )}`;

  const archivedAtTagText = `Archivé le : ${
    canceled_at ? formatDate(new Date(canceled_at), "dd/MM/yyyy") : "inconnue"
  }`;

  const sourceIdTagText = `n° de dossier : ${sourceId}`;

  return (
    <Container className="formality_card_container">
      <Header>
        <Label text={title} sizeVariant="LARGE" fontWeightVariant="normal" />
        <StyledTag color="orange">{sourceIdTagText}</StyledTag>
      </Header>
      <Body>
        <div className="tag_update">
          <StyledTag color="blue">
            {status === FormalityStatus.Cancelled
              ? archivedAtTagText
              : updateAtTagText}
          </StyledTag>
        </div>
        <div className="actions_container">
          <FormalityCTA formality={formality} ctaVariant={ctaVariant} />
          <FormalityOptionsMenu formality={formality} />
        </div>
      </Body>
    </Container>
  );
};

export default FormalityCard;
