import { Button, Column, Text, Title } from "@yolaw/ui-kit-components";
import styled, { css, useTheme } from "styled-components";
import { CapitalDeposit, LegalEntityDetails } from "types/legal-entities";
import thumbnailSrc from "../../assets/lscp-app-preview.png";

const LSCPAccessCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    background-color: ${theme.colors.primary.lighter};
    box-shadow: ${theme.shadows.green.medium};

    > .inner-container {
      display: flex;
      gap: ${theme.spacing.l}px;

      > .card-aside.text-content {
        flex: 3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: ${theme.spacing.s}px;
        padding-bottom: ${theme.spacing.s}px;

        h3 {
          text-align: center;
        }

        > button {
          align-self: center;
        }
      }

      > .card-aside.preview-img {
        flex: 2;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        > img {
          flex: 1;
          object-fit: contain;
          max-height: 281px;
        }
      }

      @container (max-width: ${theme.breakpoints.m}px) {
        flex-direction: column-reverse;
        padding: ${theme.spacing.s}px;

        > .card-aside.preview-img > img {
          margin: 0 auto;
        }
      }

      @container (min-width: ${theme.breakpoints.m}px) {
        padding: ${theme.spacing.s}px ${theme.spacing.s}px 0
          ${theme.spacing.s}px;

        > .card-aside.text-content {
          padding-left: ${theme.spacing.xxl}px;
        }
      }
    }
  `}
`;

type CapitalDepositPageAccessCardProps = {
  kDep?: CapitalDeposit;
  legalEntityDetails: LegalEntityDetails;
};

export const CapitalDepositPageAccessCard = ({
  kDep,
  legalEntityDetails,
}: CapitalDepositPageAccessCardProps) => {
  const theme = useTheme();

  const canStartDepositing = !!kDep?.redirection_url;

  const startDepositing = () => {
    kDep?.redirection_url && window.open(kDep.redirection_url);
  };

  return (
    <LSCPAccessCard>
      <div className="inner-container">
        <div className="card-aside text-content">
          <Column style={{ rowGap: theme.spacing.xxxs }}>
            {canStartDepositing ? (
              <>
                <Title type="H3">C’est à vous de jouer&nbsp;!</Title>
                <Text>
                  Vous pouvez dès maintenant déposer votre capital avec le
                  Compte Pro Legalstart.
                </Text>
              </>
            ) : (
              <>
                <Title type="H3">C’est bientôt à vous de jouer&nbsp;!</Title>
                <Text>
                  Vous recevrez un email dès que{" "}
                  {legalEntityDetails.owner.first_name}{" "}
                  {legalEntityDetails.owner.last_name} aura finalisé la création
                  de {legalEntityDetails.name}. Vous pourrez alors effectuer
                  votre dépôt de capital avec le Compte Pro Legalstart.
                </Text>
              </>
            )}
          </Column>
          <Button disabled={!canStartDepositing} onClick={startDepositing}>
            Déposer mon capital
          </Button>
        </div>

        <div className="card-aside preview-img">
          <img src={thumbnailSrc} alt="Compte Pro Preview" width={200} />
        </div>
      </div>
    </LSCPAccessCard>
  );
};
