import { CustomHTMLDialogElement } from "components/modal/Modal";
import React from "react";
import { LSPaymentMethod } from "types";

type State = {
  isSubmitting: boolean;
  modalRef: React.MutableRefObject<CustomHTMLDialogElement | null> | null;
  setupFor: LSPaymentMethod.CardProcessorType;
};

const initialState: State = {
  isSubmitting: false,
  modalRef: null,
  setupFor: LSPaymentMethod.CardProcessorType.User,
};

enum Action {
  SetIsSubmitting = "SET_IS_SUBMITTING",
  SetModalRef = "SET_MODAL_REF",
  SetSetupFor = "SET_SETUP_FOR",
}

type SetIsSubmittingAction = {
  type: Action.SetIsSubmitting;
  payload: State["isSubmitting"];
};

type SetModalRefAction = {
  type: Action.SetModalRef;
  payload: State["modalRef"];
};

type SetCardInputsAction = {
  type: Action.SetSetupFor;
  payload: State["setupFor"];
};

type DispatchAction =
  | SetIsSubmittingAction
  | SetModalRefAction
  | SetCardInputsAction;

const reducer = (state: State, action: DispatchAction): State => {
  switch (action.type) {
    case Action.SetIsSubmitting:
      return {
        ...state,
        isSubmitting: action.payload,
      };
    case Action.SetModalRef:
      return {
        ...state,
        modalRef: action.payload,
      };
    case Action.SetSetupFor:
      return {
        ...state,
        setupFor: action.payload,
      };
    default:
      throw new Error("[SetupPaymentMethodContext] unexpected action type");
  }
};

type ContextType = {
  state: State;
  dispatch: React.Dispatch<DispatchAction>;
};

const Context = React.createContext<ContextType>({
  state: initialState,
  dispatch: () => null,
});

const SetupPaymentMethodContext = {
  Action,
  Context,
  initialState,
  reducer,
};

export default SetupPaymentMethodContext;
