import { useContext, useEffect } from "react";

import { LayoutContext } from "contexts";
import { useIsMobile } from "hooks";

/** Hide the sidebar to get fluid layout (mostly target the desktop UI).
 * Show the sidebar again when unmount.
*/
export const useFluidLayout = () => {
  const isMobile = useIsMobile();
  const {
    state: { isMenuOpen },
    dispatch: layoutDispatch,
  } = useContext(LayoutContext.Context);

  useEffect(() => {
    if (!isMobile && isMenuOpen) {
      layoutDispatch({ type: "CLOSE_SIDEBAR" });
    }
    return () => {
      if (!isMobile && !isMenuOpen) {
        layoutDispatch({ type: "OPEN_SIDEBAR" });
      }
    };
  }, [isMenuOpen, isMobile, layoutDispatch]);

  return null;
};

export default useFluidLayout;
