import { useContext, useEffect } from "react";
import { PaymentError, PaymentMethod, PaymentOption } from "services/payment";
import LSUser from "types/user";
import PaymentFormContext from "./PaymentFormContext";

const usePaymentFormContext = () => {
  const { state, dispatch } = useContext(PaymentFormContext.Context);
  const { cardInputsValidity, paymentMethod, paymentOption } = state;

  const checkReadyToPayStatus = () => {
    if (paymentOption === PaymentOption.UseSavedCard)
      return !!state.selectedCard;

    switch (paymentMethod) {
      case PaymentMethod.Card:
        return state.cardInputsValidity;
      case PaymentMethod.ApplePay:
      case PaymentMethod.GooglePay:
        return true;
      default:
        return false;
    }
  };

  const setIsReadyToPay = (isReady: boolean) =>
    dispatch({
      type: PaymentFormContext.Action.SetIsReadyToPay,
      payload: isReady,
    });

  const setPaymentError = (paymentError: PaymentError | null) =>
    dispatch({
      type: PaymentFormContext.Action.SetPaymentError,
      payload: paymentError,
    });

  useEffect(() => {
    setIsReadyToPay(checkReadyToPayStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardInputsValidity, paymentMethod, paymentOption]);

  const setCardHolderName = (name: string) => {
    dispatch({
      type: PaymentFormContext.Action.SetCardHolderName,
      payload: name,
    });
  };

  const setCardInputsValidity = (validity: boolean) => {
    dispatch({
      type: PaymentFormContext.Action.SetCardInputValidity,
      payload: validity,
    });

    setIsReadyToPay(validity);
  };

  const setPaymentMethod = (method: PaymentMethod) => {
    dispatch({
      type: PaymentFormContext.Action.SetPaymentMethod,
      payload: method,
    });
  };

  const setPaymentOption = (option: PaymentOption) => {
    dispatch({
      type: PaymentFormContext.Action.SetPaymentOption,
      payload: option,
    });
  };

  const setSelectedCard = (card: LSUser.PaymentMethodItem) => {
    dispatch({
      type: PaymentFormContext.Action.SetSelectedCard,
      payload: card,
    });
  };

  return {
    state,
    /** Available actions in payment modal */
    action: {
      setCardHolderName,
      setCardInputsValidity,
      setPaymentError,
      setPaymentMethod,
      setPaymentOption,
      setSelectedCard,
    },
  };
};

export default usePaymentFormContext;
