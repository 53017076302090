import { memo, useReducer } from "react";
import { Outlet } from "react-router-dom";
import { styled } from "styled-components";

import { LayoutContext } from "../contexts";
import AdminToolbox from "./AdminToolbox";
import { APP_BANNER_ELM_ID, AppBanner } from "./AppBanner";
import { AppHeader } from "./header";

const MainLayout = styled.div`
  display: grid;
  grid-template-areas:
    "app_banner"
    "app_header"
    "app_body";
  grid-template-rows: max-content max-content 1fr;

  position: fixed;
  width: 100dvw;
  height: 100dvh;

  --modal-backdrop-color: ${({ theme }) => theme.colors.secondary.main}B2;

  &#app-container[covered] {
    --overlay-content: "";
    &:before {
      display: flex;
      content: var(--overlay-content);
      position: fixed;
      inset: 0;
      z-index: 9999;
      background-color: var(--modal-backdrop-color);
      transition: background-color 400ms ease 0s;
      will-change: background-color;
      justify-content: center;
      align-items: center;
      font-size: 80px;
    }
  }

  > #${APP_BANNER_ELM_ID} {
    grid-area: app_banner;
  }
`;

const AppBody = styled.div`
  grid-area: app_body;
  overflow: hidden;
  container-type: inline-size;
`;

export const GlobalLayout = memo(() => {
  const [layoutState, layoutDispatch] = useReducer(
    LayoutContext.reducer,
    LayoutContext.initialState
  );

  return (
    <LayoutContext.Context.Provider
      value={{ state: layoutState, dispatch: layoutDispatch }}
    >
      <AdminToolbox />
      <MainLayout id="app-container">
        <AppBanner />
        <AppHeader />
        <AppBody data-testid="app-body">
          <Outlet />
        </AppBody>
      </MainLayout>
    </LayoutContext.Context.Provider>
  );
});
