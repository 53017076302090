import { useQuery } from "@tanstack/react-query";
import { Column, Spinner, Title } from "@yolaw/ui-kit-components";
import { ModalName } from "contexts/app";
import { useApp, useIsMobile, useLegalEntity, useUser } from "hooks";
import { PageContainer } from "pages/components/PageStyles";
import { useCallback, useEffect } from "react";
import { LegalEntityService, UserService } from "services";
import { useTheme } from "styled-components";
import { LSPaymentMethod } from "types";
import PaymentMethodItem from "./components/PaymentMethodItem";
import PaymentMethodSection from "./components/PaymentMethodSection";
import DetachDefaultPaymentMethodModal from "./components/SetupModal/DetachDefaultPaymentMethodModal";
import SetupNewPaymentMethodModal from "./components/SetupModal/SetupNewPaymentMethodModal";

const LegalEntityPaymentMethod = () => {
  const app = useApp();
  const legalEntity = useLegalEntity();

  if (!legalEntity.paymentMethod) return null;

  enum ModalOpenFrom {
    AddForLE = "add-for-legal-entity",
    ChangeForLE = "change-for-legal-entity",
    DetachForLE = "detach-for-legal-entity",
  }

  const handleAdd = () => {
    app.openModal({
      name: ModalName.PaymentMethodSetup,
      openedBy: {
        context: "payment-methods-page",
        from: ModalOpenFrom.AddForLE,
      },
    });
  };

  const handleChange = () => {
    app.openModal({
      name: ModalName.PaymentMethodSetup,
      openedBy: {
        context: "payment-methods-page",
        from: ModalOpenFrom.ChangeForLE,
      },
    });
  };

  const handleRemove = () => {
    app.openModal({
      name: ModalName.PaymentMethodDetach,
      openedBy: {
        context: "payment-methods-page",
        from: ModalOpenFrom.DetachForLE,
      },
    });
  };

  const isLEModal = Object.values(ModalOpenFrom).includes(
    app.openingModal?.openedBy?.from as ModalOpenFrom
  );

  return (
    <>
      <PaymentMethodSection
        title={`Moyen de paiement de la société ${legalEntity.name}`}
        subTitle="Carte utilisée pour payer les abonnements rattachés à la société."
      >
        <PaymentMethodItem
          paymentMethod={legalEntity.paymentMethod}
          handleAdd={handleAdd}
          handleChange={handleChange}
          handleRemove={handleRemove}
        />
      </PaymentMethodSection>

      {isLEModal && app.openingModal?.name === ModalName.PaymentMethodSetup && (
        <SetupNewPaymentMethodModal
          setupFor={LSPaymentMethod.CardProcessorType.Company}
        />
      )}
      {isLEModal &&
        app.openingModal?.name === ModalName.PaymentMethodDetach && (
          <DetachDefaultPaymentMethodModal
            setupFor={LSPaymentMethod.CardProcessorType.Company}
          />
        )}
    </>
  );
};

const UserPaymentMethod = () => {
  const app = useApp();
  const user = useUser();

  if (!user.paymentMethod) return null;

  enum ModalOpenFrom {
    AddForUser = "add-for-user",
    ChangeForUser = "change-for-user",
    DetachForUser = "detach-for-user",
  }

  const handleAdd = () => {
    app.openModal({
      name: ModalName.PaymentMethodSetup,
      openedBy: {
        context: "payment-methods-page",
        from: ModalOpenFrom.AddForUser,
      },
    });
  };
  const handleChange = () => {
    app.openModal({
      name: ModalName.PaymentMethodSetup,
      openedBy: {
        context: "payment-methods-page",
        from: ModalOpenFrom.ChangeForUser,
      },
    });
  };
  const handleRemove = () => {
    app.openModal({
      name: ModalName.PaymentMethodDetach,
      openedBy: {
        context: "payment-methods-page",
        from: ModalOpenFrom.DetachForUser,
      },
    });
  };

  const isUserModal = Object.values(ModalOpenFrom).includes(
    app.openingModal?.openedBy?.from as ModalOpenFrom
  );

  return (
    <>
      <PaymentMethodSection
        title="Moyen de paiement rattaché au compte utilisateur"
        subTitle="Carte utilisée pour toutes les démarches rattachées à votre compte."
      >
        <PaymentMethodItem
          paymentMethod={user.paymentMethod}
          handleAdd={handleAdd}
          handleChange={handleChange}
          handleRemove={handleRemove}
        />
      </PaymentMethodSection>

      {isUserModal &&
        app.openingModal?.name === ModalName.PaymentMethodSetup && (
          <SetupNewPaymentMethodModal
            setupFor={LSPaymentMethod.CardProcessorType.User}
          />
        )}

      {isUserModal &&
        app.openingModal?.name === ModalName.PaymentMethodDetach && (
          <DetachDefaultPaymentMethodModal
            setupFor={LSPaymentMethod.CardProcessorType.User}
          />
        )}
    </>
  );
};

const PaymentMethodsPage = () => {
  const app = useApp();
  const isMobile = useIsMobile();
  const theme = useTheme();

  const getPaymentMethods = useCallback(async () => {
    if (app.currentLegalEntityId) {
      const pms = await LegalEntityService.getDefaultPaymentMethods(
        app.currentLegalEntityId
      );
      return pms;
    } else {
      const pm = await UserService.getDefaultPaymentMethod();
      return [pm];
    }
  }, [app.currentLegalEntityId]);

  const query = useQuery({
    queryKey: ["paymentMethods", app.currentLegalEntityId],
    queryFn: getPaymentMethods,
  });

  const { isPending, data } = query;

  useEffect(() => {
    if (data) {
      app.setPaymentMethods(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <PageContainer>
        <Column style={{ rowGap: theme.spacing.l }}>
          <Title type={isMobile ? "H1" : "H2"}>
            Gérer mon moyen de paiement
          </Title>

          {isPending ? (
            <Spinner />
          ) : (
            <>
              {/* LE's PM */}
              <LegalEntityPaymentMethod />
              {/* User's PM */}
              <UserPaymentMethod />
            </>
          )}
        </Column>
      </PageContainer>
    </>
  );
};

export default PaymentMethodsPage;
