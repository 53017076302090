import { Button, Column, Hint, HintType, Text } from "@yolaw/ui-kit-components";
import { QuestionBubbleFlat } from "@yolaw/ui-kit-icons";
import Popover from "components/Popover/Popover";
import PopoverContent from "components/Popover/PopoverContent";
import PopoverTrigger from "components/Popover/PopoverTrigger";
import { useMemo, useRef, useState } from "react";
import styled, { css, useTheme } from "styled-components";
import { LSPaymentMethod } from "types";
import DateTimeUtils from "utils/datetime";
import BankCardInfo from "./BankCardInfo";
import SEPADebitInfo from "./SEPADebitInfo";

const PaymentMethodItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    row-gap: ${theme.spacing.xs}px;
    max-width: 600px;

    .actions_container {
      display: flex;
      flex-direction: column;
      row-gap: inherit;

      .us_tertiary_btn {
        color: ${theme.colors.secondary.main};
        font-weight: normal;
        text-decoration: underline;
        &:disabled {
          color: ${theme.colors.neutral.lighter};
        }
      }

      .remove_btn_container {
        display: flex;
        column-gap: ${theme.spacing.xxxs}px;
        align-self: center;
        align-items: center;
      }
    }

    .actions_container {
      max-width: 296px;
    }
  `}
`;

const PopoverContentContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing.xxxs}px;
    max-width: 300px;
    background-color: ${theme.colors.common.white};
    border-radius: ${theme.borderRadius.m}px;
    padding: ${theme.spacing.s}px;
    box-shadow: ${theme.shadows.blue.medium};
    &:focus {
      outline: none;
    }
  `}
`;

const UnremovablePopover = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const closeMenu = () => setOpen(false);
  const openMenu = () => setOpen(true);
  const toggleOpen = () => setOpen(!open);

  const ref = useRef<HTMLDivElement>(null);

  return (
    <Popover placement="right-start" open={open}>
      <PopoverTrigger ref={ref}>
        <div
          onMouseEnter={openMenu}
          onMouseLeave={closeMenu}
          onClick={toggleOpen}
        >
          <QuestionBubbleFlat size="1em" color={theme.colors.neutral.lighter} />
        </div>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverContentContainer>
          <Text fontWeightVariant="bold">
            Vous ne pouvez pas supprimer ce moyen de paiement
          </Text>
          <Text color="neutral.dark">
            Vous avez des abonnements actuellement prélevés sur ce moyen de
            paiement.
          </Text>
        </PopoverContentContainer>
      </PopoverContent>
    </Popover>
  );
};

type RemoveButtonProps = {
  onClick?: VoidFunction;
} & Pick<PaymentMethodItemProps, "paymentMethod" | "handleRemove">;
const RemoveButton = ({
  paymentMethod: pmo,
  handleRemove,
}: RemoveButtonProps) => {
  const { can_be_detached } = pmo;
  return (
    <div className="remove_btn_container">
      <Button
        variant="tertiary"
        disabled={!can_be_detached}
        className="us_tertiary_btn"
        size="small"
        onClick={handleRemove}
      >
        Suprimer le moyen de paiement
      </Button>
      {!can_be_detached && <UnremovablePopover />}
    </div>
  );
};

enum CardWarning {
  Expired = "expired",
  ExpiresSoon = "expires_soon",
}

type PaymentMethodItemProps = {
  paymentMethod: LSPaymentMethod.PaymentMethodObject;
  handleAdd?: VoidFunction;
  handleChange?: VoidFunction;
  handleRemove?: VoidFunction;
};

const PaymentMethodItem = ({
  paymentMethod: pmo,
  handleAdd,
  handleChange,
  handleRemove,
}: PaymentMethodItemProps) => {
  const theme = useTheme();

  const { payment_method } = pmo;
  const { exp_month, exp_year } = payment_method?.card || {};

  const cardWarning = useMemo(() => {
    if (!exp_month || !exp_year) return null;

    const expDate = new Date();
    expDate.setFullYear(exp_year, exp_month);
    const monthDiff = DateTimeUtils.getMonthsDifference(expDate);

    return monthDiff < 0
      ? CardWarning.Expired
      : monthDiff < 2
      ? CardWarning.ExpiresSoon
      : null;
  }, [exp_month, exp_year]);

  return (
    <PaymentMethodItemContainer>
      {cardWarning && (
        <Hint type={HintType.Error}>
          <Column style={{ rowGap: theme.spacing.xxxs }}>
            <Text fontWeightVariant="bold">
              {cardWarning === CardWarning.Expired
                ? "Votre carte a expiré"
                : "Votre carte de paiement arrive à expiration"}
            </Text>
            <Text color="neutral.darker">
              {cardWarning === CardWarning.Expired
                ? "Mettez à jour votre carte pour ne éviter l'interruption de services sur vos abonnements."
                : "Mettez à jour dès maintenant votre carte de paiement pour éviter de perdre l'accès à vos abonnements."}
            </Text>
          </Column>
        </Hint>
      )}

      {!payment_method ? (
        <BankCardInfo card={undefined} cardHolderName={null} />
      ) : payment_method.type === "card" ? (
        <BankCardInfo
          card={payment_method.card}
          cardHolderName={
            payment_method.billing_details.name ||
            payment_method.metadata.card_holder_name
          }
        />
      ) : payment_method.type === "sepa_debit" ? (
        <SEPADebitInfo sepaDebit={payment_method.sepa_debit} />
      ) : (
        <Hint type={HintType.Warning}>
          Le type de mode de paiement «&nbsp;{payment_method?.type}&nbsp;» n'est
          pas pris en charge.
        </Hint>
      )}

      <div className="actions_container">
        {!payment_method ? (
          <Button icon="PlusCircleFlatV3" size="small" onClick={handleAdd}>
            Ajouter mon moyen de paiement
          </Button>
        ) : (
          <>
            <Button
              variant={cardWarning ? "primary" : "secondary"}
              size="small"
              onClick={handleChange}
            >
              {cardWarning
                ? "Mettre à jour le moyen de paiement"
                : "Modifier mon moyen de paiement"}
            </Button>
            <RemoveButton paymentMethod={pmo} handleRemove={handleRemove} />
          </>
        )}
      </div>
    </PaymentMethodItemContainer>
  );
};

export default PaymentMethodItem;
