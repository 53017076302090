import { Button } from "@yolaw/ui-kit-components";
import { LegalStartLogo } from "@yolaw/ui-kit-icons";
import { useOutsideClick } from "hooks";
import { LegalEntitySwitch } from "layout/legalEntitySwitch";
import React, { FC, useRef, useState } from "react";
import { css, styled } from "styled-components";
import MyAccountMenu from "./MyAccountMenu";

const StyledHeader = styled.header`
  grid-area: app_header;
  z-index: 5000;

  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.neutral.lightest};
    background-color: ${theme.colors.common.white};
  `}
`;

const InnerContainer = styled.div`
  position: relative;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > #app_header-middle {
    max-inline-size: fit-content;
  }
  > #app_header-right {
    > button {
      text-wrap-mode: nowrap;
    }
  }
  > * {
    flex-shrink: 0;
  }

  ${({ theme }) => css`
    column-gap: ${theme.spacing.s}px;
    padding: ${theme.spacing.xs}px ${theme.spacing.s}px;
  `}
`;

const LogoLink = styled.a`
  display: inline-block;
  flex-basis: 190px;
`;

const MyAccountMenuContainer = styled.div`
  position: absolute;
  right: 0;
  box-shadow: 0px 10px 20px 0px rgba(45, 60, 86, 0.1);
  transition: all 300ms ease;

  ${({ theme }) => css`
    border-radius: 0 0 ${theme.borderRadius.s}px ${theme.borderRadius.s}px;
    overflow: hidden;
  `}
`;

const HeaderDesktop: FC = () => {
  const [myAccountMenuVisible, setMyAccountMenuVisible] = useState(false);

  const myAccountMenuRef = useRef<HTMLDivElement>(null);

  const toggleMyAccountMenuVisible = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setMyAccountMenuVisible(!myAccountMenuVisible);
  };

  const closeMyAccountMenu = () => setMyAccountMenuVisible(false);

  useOutsideClick(myAccountMenuRef, closeMyAccountMenu);

  return (
    <StyledHeader data-testid="app-header">
      <InnerContainer>
        <ContentContainer>
          <LogoLink href={process.env.REACT_APP_LEGALSTART_CMS_URL}>
            <LegalStartLogo height={40} />
          </LogoLink>

          <div id="app_header-middle">
            <LegalEntitySwitch />
          </div>

          <div id="app_header-right">
            <Button
              variant="secondary"
              size="small"
              onClick={toggleMyAccountMenuVisible}
            >
              Mon compte
            </Button>
          </div>
        </ContentContainer>

        <MyAccountMenuContainer
          ref={myAccountMenuRef}
          style={{ display: myAccountMenuVisible ? "block" : "none" }}
        >
          <MyAccountMenu onItemClick={closeMyAccountMenu} />
        </MyAccountMenuContainer>
      </InnerContainer>
    </StyledHeader>
  );
};

export default HeaderDesktop;
