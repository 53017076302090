import { FunnelWireTransferDetails } from "types/formalities";
import coreApiInstance from "../coreApi";

export const getFunnelWireTransferDetails = (funnelId: number) =>
  coreApiInstance.get<FunnelWireTransferDetails>(
    `/funnels/${funnelId}/wire-transfer-details/`
  );

export const cancelWireTransfer = (funnelId: number) =>
  coreApiInstance.post<{ redirection_url: string }>(`/funnels/${funnelId}/cancel-wire-transfer/`);
