import { useContext } from "react";
import LegacySubscription from "types/legacy-subscriptions";
import PaymentModalContext, {
  PaymentModalContextAction,
  PaymentModalStep,
} from "./context";

export const usePaymentModalContext = () => {
  const { state, dispatch } = useContext(PaymentModalContext.Context);
  const { selectedPlanSlug, subscription } = state;

  const getPlan = (slug: string | null) => {
    if (!slug || !subscription) return undefined;
    return subscription.plans.find((plan) => plan.slug === slug);
  };

  const selectedPlan = getPlan(selectedPlanSlug);

  const setPaymentModalStep = (stepName: PaymentModalStep) =>
    dispatch({
      type: PaymentModalContextAction.SetPaymentModalStep,
      payload: stepName,
    });

  const setSelectedPlanSlug = (slug: LegacySubscription.Plan["slug"]) =>
    dispatch({
      type: PaymentModalContextAction.SetSelectedPlanSlug,
      payload: slug,
    });

  return {
    state,
    selectedPlan,
    /** Available actions in payment modal */
    action: {
      getPlan,
      setPaymentModalStep,
      setSelectedPlanSlug,
    },
  };
};
