import { SubscriptionViewPath } from "pages/subscriptions-management/router";
import { useReducer } from "react";
import { Route, Routes } from "react-router-dom";
import BenefitsComparisonPage from "./downsell/BenefitsComparisonPage";
import ChangePlanContext from "./ChangePlanContext";
import SwitchTierSuccessModal from "./components/SwitchTierSuccessModal";
import DownsellConfirmationModal from "./downsell/DownsellConfirmationModal";
import PlanSelectionPage from "./PlanSelectionPage";
import UpsellConfirmationModal from "./upsell/UpsellConfirmationModal";
import { useApp } from "hooks";
import { ModalName } from "contexts/app";

const ChangePlanPage = () => {
  const app = useApp();
  const [changePlanState, changePlanDispatch] = useReducer(
    ChangePlanContext.reducer,
    ChangePlanContext.initialState
  );

  return (
    <ChangePlanContext.Context.Provider
      value={{ state: changePlanState, dispatch: changePlanDispatch }}
    >
      <Routes>
        <Route index element={<PlanSelectionPage />} />
        <Route
          path={SubscriptionViewPath.BenefitsComparisonPage}
          element={<BenefitsComparisonPage />}
        />
      </Routes>

      {app.openingModal?.name === ModalName.SubsMgmntUpsellConfirmation && (
        <UpsellConfirmationModal />
      )}
      {app.openingModal?.name === ModalName.SubsMgmntDownsellConfirmation && (
        <DownsellConfirmationModal />
      )}
      {app.openingModal?.name === ModalName.SubsMgmntSwitchTierSuccess && (
        <SwitchTierSuccessModal />
      )}
    </ChangePlanContext.Context.Provider>
  );
};

export default ChangePlanPage;
