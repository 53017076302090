import { PaymentRequestButtonElement } from "@stripe/react-stripe-js";
import {
  PaymentRequestPaymentMethodEvent,
  StripePaymentRequestButtonElementClickEvent,
} from "@stripe/stripe-js";
import { Button } from "@yolaw/ui-kit-components";
import useStripePayment from "hooks/useStripePayment";
import styled, { css } from "styled-components";

const ButtonContainer = styled.div<{ disabled: boolean }>`
  ${({ disabled, theme }) => css`
    .StripeElement {
      opacity: ${disabled ? 0.6 : 1};
    }

    @container (max-width: ${theme.breakpoints.s}px) {
      width: 100%;
    }

    @container (min-width: ${theme.breakpoints.s}px) {
      width: clamp(120px, 20%, 320px);
    }
  `}
`;

type Props = {
  disabled?: boolean;
  isLoading: boolean;
  onPaymentMethodCollected: (
    stripePaymentMethodId: string,
    event?: PaymentRequestPaymentMethodEvent
  ) => void;
};
const SetupWithApplePayButton = ({
  disabled,
  isLoading,
  onPaymentMethodCollected,
}: Props) => {
  const { stripePaymentRequest } = useStripePayment();

  // Handling Stripe Payment Request for Google Pay or Apple Pay
  const handleStripePaymentMethodChange = async (
    event: PaymentRequestPaymentMethodEvent
  ) => {
    onPaymentMethodCollected(event.paymentMethod.id, event);
  };

  // Handling Stripe Payment Request for Google Pay or Apple Pay
  const handleStripePaymentRequestCancel = () => {
    // Turn off the payment method event listener
    stripePaymentRequest?.off("paymentmethod", handleStripePaymentMethodChange);
  };

  const handleButtonClick = (
    event: StripePaymentRequestButtonElementClickEvent
  ) => {
    if (disabled) {
      event.preventDefault();
    } else {
      stripePaymentRequest?.update({
        total: {
          label: "Configurer le moyen de paiement",
          amount: 0,
          pending: false,
        },
      });

      // Listen to payment method change (payment method is confirmed/authorized)
      stripePaymentRequest?.on(
        "paymentmethod",
        handleStripePaymentMethodChange
      );

      // Listen to payment request cancel (payment interface is dismissed by user)
      stripePaymentRequest?.on("cancel", handleStripePaymentRequestCancel);
    }
  };

  if (isLoading || !stripePaymentRequest) {
    return <Button isLoading={true}> Pay</Button>;
  }

  return (
    <ButtonContainer disabled={!!disabled}>
      <PaymentRequestButtonElement
        options={{
          paymentRequest: stripePaymentRequest!,
          style: {
            paymentRequestButton: { type: "default", height: "50px" },
          },
        }}
        onClick={handleButtonClick}
      />
    </ButtonContainer>
  );
};

export default SetupWithApplePayButton;
