import { Button, Column, Tag, Text, Title } from "@yolaw/ui-kit-components";
import {
  BenefitGroup,
  SubscriptionProductBenefit,
} from "services/subscriptions/conf/subs-extra-data-types";
import BenefitIcon, {
  BenefitIconType,
} from "services/subscriptions/conf/utils/BenefitIcon";
import {
  computeBenefitLabelWithProductAvailability,
  findProductAvailability,
} from "services/subscriptions/conf/utils/product-benefits";
import styled, { css } from "styled-components";
import { SubscriptionPlan, SubscriptionTier } from "types/subscriptions";
import NumberUtils from "utils/number";
import TranslatorUtils from "utils/translator";
import BenefitList from "./BenefitList";

const Container = styled.div`
  flex-basis: 344px;
  flex-grow: 0;

  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.m}px;
    box-shadow: ${theme.shadows.blue.small};

    &:not(:hover) {
      border: 1px solid transparent;
    }

    &:hover {
      border: 1px solid ${theme.colors.accentuation.main};
    }
  `}
`;

const ContentContainer = styled<React.ElementType>(Column)`
  ${({ theme }) => css`
    && {
      gap: ${theme.spacing.m}px;
    }
    padding: ${theme.spacing.s}px;

    .price {
      display: flex;
      align-items: center;
      column-gap: ${theme.spacing.xxxs}px;
    }

    .action {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 56px;

      > .tag {
        height: 40px;
        padding-inline: ${theme.spacing.xs}px;
      }
    }

    .benefit_groups_list_container {
      > .benefit_group:not(:last-child) {
        border-bottom: 1px solid ${theme.colors.neutral.lighter};
        padding-bottom: ${theme.spacing.xs}px;
        margin-bottom: ${theme.spacing.xs}px;
      }

      .benefit_group.container {
        display: flex;
        flex-direction: column;
        row-gap: ${theme.spacing.xxxs}px;
      }
    }
  `}
`;

type BenefitItemProps = {
  benefit: SubscriptionProductBenefit<string, string>;
  productSlug: string;
  isHighlighted: boolean;
};
const BenefitItem = ({
  benefit,
  productSlug,
  isHighlighted,
}: BenefitItemProps) => {
  const productAvailability = findProductAvailability(
    benefit.productAvailabilities,
    productSlug
  );

  return (
    <li className="benefit_item">
      <BenefitIcon
        type={
          !!productAvailability
            ? BenefitIconType.Positive
            : BenefitIconType.Negative
        }
      />
      <Text
        type="small"
        color={isHighlighted ? "primary.dark" : "neutral.dark"}
        fontWeightVariant={isHighlighted ? "bold" : undefined}
      >
        {computeBenefitLabelWithProductAvailability(
          benefit.label,
          productAvailability
        )}
      </Text>
    </li>
  );
};

type PlanItemProps = {
  benefitGroups: BenefitGroup<string, string>[] | undefined;
  buttonTextPrefix?: string;
  buttonVariant?: "primary" | "secondary";
  currentTier?: SubscriptionTier;
  onPlanSelect: (plan: SubscriptionPlan) => void;
  plan: SubscriptionPlan;
  tierToSell: SubscriptionTier;
} & Partial<HTMLDivElement>;

const SubscriptionPlanItem = ({
  benefitGroups,
  buttonTextPrefix = "Choisir la formule",
  buttonVariant = "primary",
  className,
  currentTier,
  onPlanSelect,
  plan,
  tierToSell,
}: PlanItemProps) => {
  const isCurrentTier = currentTier?.id && currentTier.id === tierToSell.id;

  const handlePlanSelect = () => {
    onPlanSelect(plan);
  };

  return (
    <Container className={className}>
      <ContentContainer>
        <div className="header">
          <Title type="H4">{plan.product.name.toUpperCase()}</Title>

          <div className="price">
            <Title type="H3">
              {NumberUtils.currencyFormat(tierToSell.flat_price)}
            </Title>
            <Text type="small">
              HT / {TranslatorUtils.translate(plan.period_unit)} sans engagement
            </Text>
          </div>
        </div>

        <div className="action">
          {isCurrentTier ? (
            <Tag color="green" size="large" className="tag">
              Votre abonnement actuel
            </Tag>
          ) : (
            <Button
              variant={buttonVariant}
              onClick={handlePlanSelect}
            >{`${buttonTextPrefix} ${plan.product.name}`}</Button>
          )}
        </div>

        {benefitGroups && (
          <div className="benefit_groups_list_container">
            {benefitGroups.map((g) => (
              <div className="benefit_group container" key={g.label}>
                <Text fontWeightVariant="bold" color="neutral.dark">
                  {g.label}
                </Text>
                <BenefitList className="benefits_list">
                  {g.items.map((i) => (
                    <BenefitItem
                      key={i.label}
                      benefit={i}
                      productSlug={plan.product.slug}
                      isHighlighted={!!g.highlightedFeatures?.includes(i.label)}
                    />
                  ))}
                </BenefitList>
              </div>
            ))}
          </div>
        )}
      </ContentContainer>
    </Container>
  );
};

export default SubscriptionPlanItem;
