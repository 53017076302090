import {
  BenefitProductAvailabilities,
  BenefitProductAvailability,
} from "services/subscriptions/conf/subs-extra-data-types";
import { SubscriptionPlan } from "types/subscriptions";

export const findProductAvailability = (
  productAvailabilities: BenefitProductAvailabilities<string>,
  productSlug: SubscriptionPlan["product"]["slug"]
) =>
  productAvailabilities.find((pa) =>
    typeof pa === "string"
      ? pa === productSlug
      : typeof pa === "object" && "product" in pa && pa.product === productSlug
  );

export const getCountDiff = (
  a?: BenefitProductAvailability<string>,
  b?: BenefitProductAvailability<string>
) => {
  if (typeof a === "object" && typeof b === "object") {
    return Math.abs(a.count - b.count);
  }

  return 0;
};

export const computeBenefitLabel = (label: string, count = 0) =>
  label.replace(`{{count}}`, String(count));

export const computeBenefitLabelWithProductAvailability = (
  label: string,
  productAvailability?: BenefitProductAvailability<string>
): string => {
  return typeof productAvailability === "object" && productAvailability.count
    ? computeBenefitLabel(label, productAvailability.count)
    : label;
};
