import { Column, Text, Title } from "@yolaw/ui-kit-components";
import { useIsMobile, useLegalEntity } from "hooks";
import { PageContainer } from "pages/components/PageStyles";
import styled, { css } from "styled-components";
import { SubscriptionList } from "./components/SubscriptionList";

const Container = styled(PageContainer)`
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    color: ${theme.colors.secondary.main};

    @container (max-width: ${theme.breakpoints.m}px) {
      row-gap: ${theme.spacing.l}px;
    }

    @container (min-width: ${theme.breakpoints.m + 1}px) {
      row-gap: ${theme.spacing.s}px;
    }
  `}
`;

const HeaderContainer = styled(Column)`
  && {
    row-gap: ${({ theme }) => theme.spacing.xxxs}px;
  }
`;

const SubscriptionsListingPage = () => {
  const isMobile = useIsMobile();
  const legalEntity = useLegalEntity();

  return (
    <Container>
      <HeaderContainer>
        <Title type={isMobile ? "H1" : "H2"}>Gérer mes abonnements</Title>
        <Text color="neutral.darker">
          Vous pouvez ici gérer les abonnements associés à la société{" "}
          <strong>{legalEntity.name}</strong>
        </Text>
      </HeaderContainer>

      <SubscriptionList />
    </Container>
  );
};

export default SubscriptionsListingPage;
