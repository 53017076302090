import { createContext } from "react";
import {
  Subscription,
  SubscriptionPlan,
  SubscriptionTier,
} from "types/subscriptions";
import SubscriptionExtraData from "../../../services/subscriptions/conf/subs-extra-data-types";

type State = {
  currentSubscription?: Subscription;
  /** The similar ACTIVE Plan with same Product's slug & Plan's period unit as the current one.
   * @warning the ID could be different
  */
  currentPlan?: SubscriptionPlan;
  /** The similar ACTIVE Tier with same prices (flat_price & total_price_it) as the current one.
   * @warning the ID could be different
   */
  currentTier?: SubscriptionTier;
  plans?: SubscriptionPlan[];
  subsLocalExtraData?: SubscriptionExtraData<string, string>;
};

const initialState: State = {};

enum ActionType {
  SetCurrentSubscription,
  SetPlans,
  SetSubsLocalExtraData,
}

type DispatchAction =
  | {
      type: ActionType.SetCurrentSubscription;
      payload: State["currentSubscription"];
    }
  | {
      type: ActionType.SetPlans;
      payload: State["plans"];
    }
  | {
      type: ActionType.SetSubsLocalExtraData;
      payload: State["subsLocalExtraData"];
    };

const reducer = (state: State, action: DispatchAction): State => {
  switch (action.type) {
    case ActionType.SetCurrentSubscription:
      return {
        ...state,
        currentSubscription: action.payload,
      };
    case ActionType.SetPlans:
      const currentPlanActive = action.payload?.find(
        (p) =>
          p.product.slug === state.currentSubscription?.product.slug &&
          p.period_unit === state.currentSubscription?.plan.period_unit
      );
      return {
        ...state,
        plans: action.payload,
        currentPlan: currentPlanActive,
        currentTier: currentPlanActive?.tiers.find(
          (t) =>
            t.flat_price === state.currentSubscription?.tier.flat_price &&
            t.total_price_it === state.currentSubscription?.tier.total_price_it
        ),
      };
    case ActionType.SetSubsLocalExtraData:
      return {
        ...state,
        subsLocalExtraData: action.payload,
      };
    default:
      return state;
  }
};

type ContextType = {
  state: State;
  dispatch: React.Dispatch<DispatchAction>;
};

const Context = createContext<ContextType>({
  state: initialState,
  dispatch: () => null,
});

Context.displayName = "SubscriptionViewContext"

const SubscriptionViewContext = {
  ActionType,
  Context,
  initialState,
  reducer,
};

export default SubscriptionViewContext;
