export enum SubscriptionFamilySlug {
  LSBusinessCapitalDeposit = "ls-business-capital-deposit",
}

interface SubscriptionFamily {
  id: number;
  display_name?: string | null;
  name: string;
  slug: SubscriptionFamilySlug;
}

interface SubscriptionProduct {
  id: number;
  name: string;
  slug: string;
}

export interface SubscriptionTier {
  id: number;
  flat_price: number;
  price_per_unit: number;
  up_to: number | null;
  total_tax: number | null;
  total_price_it: number | null;
}

interface SubscriptionPlanShort {
  id: number;
  period_unit: string;
  period_interval: number;
  trialing_unit: string | null;
  trialing_amount: number | null;
  is_active: boolean;
}

export interface SubscriptionPlan
  extends Omit<SubscriptionPlanShort, "is_active"> {
  family: SubscriptionFamily;
  product: SubscriptionProduct;
  tiers: SubscriptionTier[];
}

export enum SubscriptionStatus {
  Active = "active",
  Canceled = "canceled",
  Draft = "draft",
  Incomplete = "incomplete",
  IncompleteExpired = "incomplete_expired",
  PastDue = "past_due",
  Paused = "paused",
  Trialing = "trialing",
  /** @deprecated Defined in BE but seems not in used */
  Unpaid = "unpaid",
}

export enum SubscriptionPlannedAction {
  CANCEL = "CANCEL",
  UPSELL = "UPSELL",
  DOWNSELL = "DOWNSELL",
  CYCLE_CHANGE = "CYCLE_CHANGE",
}

export interface Subscription {
  family: SubscriptionFamily;
  id: number;
  legal_entity_id: number;
  product: SubscriptionProduct;
  plan: SubscriptionPlanShort;
  redirection_url: string | null;
  status: SubscriptionStatus;

  next_billing_amount: number | null;
  next_billing_date: string | null;

  planned_action: SubscriptionPlannedAction | null;
  /** When `planned_action == UPSELL | DOWNSELL` */
  next_tier_id: number | null;
  /** When `planned_action == CANCEL` */
  cancel_at_period_end: boolean;
  canceled_at: string | null;
  cancellation_planned_date: string | null;
  requested_cancellation_at: string | null;

  tier: SubscriptionTier;

  created_at: string;
  trial_start_date: string | null;
  trial_end_date: string | null;
  current_period_start_date: string | null;
  current_period_end_date: string | null;
}

export interface ProratedAmountResponseData {
  prorata_amount: number;
}

export interface TerminateSubscriptionResponseData {
  message: string;
}

export default Subscription;
