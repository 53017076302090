import {
  Button,
  Column,
  displayToast,
  Text,
  Title,
} from "@yolaw/ui-kit-components";
import Modal from "components/modal/Modal";
import { useApp, useIsMobile } from "hooks";
import useAppRoutes from "hooks/useAppRoutes";
import workingOnItImgSrc from "pages/subscriptions-management/assets/undraw_dev_focus_re_6iwt 1.svg";
import { useState } from "react";
import { BuiltRoutePath } from "services/router";
import { useTheme } from "styled-components";
import { ERROR_MESSAGES } from "utils/constants";

const CONTACT_EMAIL = "resiliation@legalstart.fr";

const TerminateConfirmationModal = () => {
  const app = useApp();
  const { navigateTo } = useAppRoutes();
  const isMobile = useIsMobile();
  const theme = useTheme();
  const [isUpdating, setIsUpdating] = useState(false);

  const closeModal = () => {
    app.closeModal();
  };

  const backToSubscriptionListing = async () => {
    setIsUpdating(true);
    try {
      // Update subscriptions with latest info
      await app.refreshSubscriptions();
    } catch (error: any) {
      displayToast({
        type: "error",
        content: ERROR_MESSAGES.DEFAULT,
      });
    } finally {
      setIsUpdating(false);
    }

    closeModal();

    navigateTo(BuiltRoutePath.SubscriptionsManagementPage, { replace: true });
  };

  return (
    <Modal isOpen={true} onClose={closeModal}>
      <Column
        style={{
          rowGap: `${theme.spacing.s}px`,
          alignItems: "center",
        }}
      >
        <img
          src={workingOnItImgSrc}
          style={{ width: 162, height: 104 }}
          alt="working on it"
        />

        <span style={{ textAlign: isMobile ? "left" : "center" }}>
          <Title type="H3">
            Merci de contacter nos agents pour finaliser votre résiliation
          </Title>
        </span>

        <p
          style={{
            textAlign: isMobile ? "left" : "center",
            color: theme.colors.neutral.darker,
          }}
        >
          <Text>
            Pour connaître les modalités de résiliation, nous vous invitons à
            envoyer un email à l’adresse suivante&nbsp;:{" "}
            <a style={{ color: "inherit" }} href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
          </Text>
        </p>

        <Button
          onClick={backToSubscriptionListing}
          disabled={isUpdating}
          isLoading={isUpdating}
        >
          J'ai compris
        </Button>
      </Column>
    </Modal>
  );
};

export default TerminateConfirmationModal;
